import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {ProductTreatmentRequest} from '../../order/core/_models'

export type FilterProduct = {
  set_brand_partnership_id?: boolean
  brand_partnership_id?: string
  set_category_id?: boolean
  category_id?: string
  set_outlet_id?: boolean,
  outlet_id?: string,
  set_code_product?: boolean
  code_product?: string
  set_name?: boolean
  name?: string
  set_tags?: boolean
  tags?: string[]
  set_description?: boolean
  description?: string
  set_order_number?: boolean
  order_number?: number
  set_is_active_product?: boolean
  is_active_product?: boolean
  set_is_active_price?: boolean
  is_active_price?: boolean
}

export type Product = {
  id?: string
  name?: string
  tags?: string[]
  category?: Category
  created_at?: string
  created_by?: string
  images_url?: string[]
  updated_at?: string
  updated_by?: string
  description?: string
  code_product?: string
  image_thumbnail_url?: string
  brand_partnership_id?: string
  variant?: Variant[]
  collection?: Collection[]
  combination?: Combination[]
}

export type Variant = {
  type: string
  variant: string
  product_variant_id: string
}

export type Combination = {
  sku?: string
  price?: number | null
  stock?: number | null
  width?: number
  height?: number
  length?: number
  weight?: number
  barcode?: string
  created_at: string
  created_by?: string
  updated_at?: string | null
  updated_by?: string | null
  variant?: Variant[]
}

export type Category = {
  id?: string
  name?: string
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  description?: string
  order_number?: number
  code_category?: string
  image_thumbnail_url?: string
}

export type Collection = {
  id?: string
  id_mapping?: string
  code_collection?: string
  image_thumbnail_url?: string
  name?: string
  description?: string
}

export type VariantType = {
  product_variant_type?: string
}

// Product Variant Mobile
export type ProductByVariant = {
  id?: string
  name?: string
  tags?: any[]
  category?: Category
  collection?: null
  created_at?: Date
  created_by?: string
  images_url?: any[]
  updated_at?: Date
  updated_by?: string
  combination?: VariantCombination[]
  description?: string
  code_product?: string
  image_thumbnail_url?: string
  brand_partnership_id?: null
}

export type VariantCombination = {
  sku?: string
  price?: Price[]
  stock?: Stock[]
  width?: number
  height?: number
  length?: number
  weight?: number
  barcode?: string
  variant?: Variant[]
  created_at?: Date
  created_by?: string
  updated_at?: null
  updated_by?: null
}

export type Price = {
  id?: string
  hpp?: number
  price?: number
  outlet?: Outlet
  discount?: number
  hpp_before?: number
  sell_price?: number
  price_before?: number
  discount_type?: string
  minimal_stock?: number
  is_online?: boolean
  is_offline?: boolean
}

export type Outlet = {
  id?: string
  area?: string
  name?: string
  address?: null
  outlet_code?: null
}

export type Stock = {
  outlet_id?: string
  sum_stock?: number
  product_variant_sku?: string
}

export type ProductVariant = {
  type?: string
  variant?: string
  product_variant_id?: string
}

export type ProductPrice = {
  id?: string
  hpp?: number
  price?: number
  discount?: number
  outlet_id?: string
  created_at?: string
  created_by?: string
  hpp_before?: number
  sell_price?: number
  updated_at?: string
  updated_by?: string
  price_before?: number
  discount_type?: string
  minimal_stock?: number
  product_variant_sku?: string
  sales_type?: SalesType
  status?: boolean
  product?: ProductPriceProduct
}

type ProductPriceProduct = {
  name?: string,
  category?: string
}

export type FilterProductPrice = {
  set_product_variant_sku?: boolean
  product_variant_sku?: string
  set_outlet_id?: boolean
  outlet_id?: string
  set_sales_type_id?: boolean
  sales_type_id_value?: string
  set_is_active?: boolean
  is_active?: boolean
}

export type SalesType = {
  id?: string
  name?: string
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  mdr_percentage_value?: number
}

export type FilterSalesType = {
  set_name: boolean
  name_value: string
  set_mdr_percentage: boolean
  mdr_percentage_value: 0
}

export type ProductQueryResponse = BaseResponse<Array<Product>>
export type ProductByVariantQueryResponse = BaseResponse<Array<ProductByVariant>>
export type ProductPriceQueryResponse = BaseResponse<Array<ProductPrice>>
export type SalesTypeQueryResponse = BaseResponse<Array<SalesType>>

export const initialProduct: Product = {
  image_thumbnail_url: 'https://picsum.photos/400x400',
  name: 'Product A',
  category: {
    name: 'Category A',
  },
  description:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
}

export const initialFilter: BaseRequest<FilterProduct> = {
  filter: {
    set_brand_partnership_id: false,
    brand_partnership_id: '',
    set_category_id: false,
    category_id: '',
    set_outlet_id: false,
    outlet_id: "",
    set_code_product: false,
    code_product: '',
    set_name: false,
    name: '',
    set_description: false,
    description: '',
    set_is_active_product: true,
    is_active_product: true,
    set_is_active_price: false,
    is_active_price: false,
  },
  limit: 25,
  page: 1,
  is_all_data: false,
  order: 'created_at',
  sort: 'DESC',
}

export const initialFilterProductPrice: BaseRequest<FilterProductPrice> = {
  filter: {
    set_product_variant_sku: false,
    product_variant_sku: 'SKU-13',
    set_outlet_id: false,
    outlet_id: '',
    set_sales_type_id: false,
    sales_type_id_value: '',
    set_is_active: false,
    is_active: false,
  },
  limit: 10,
  page: 1,
  order: 'created_at',
  sort: 'DESC',
}

export const initialFilterSalesType: BaseRequest<FilterSalesType> = {
  filter: {
    set_name: false,
    name_value: "",
    set_mdr_percentage: false,
    mdr_percentage_value: 0
  },
  limit: 10,
  page: 1,
  order: 'created_at',
  sort: 'DESC',
}

export const initialFilterProductByVariant: ProductTreatmentRequest = {
  filter: {
    set_product_id: false,
    product_id: '',
    set_outlet_id: false,
    outlet_id: '',
    set_sku: false,
    sku: 'SKU-TEST-006',
    set_barcode: false,
    barcode: '',
    set_type: false,
    type: '',
    set_variant: false,
    variant: '',
    set_category_id: false,
    category_id: '',
    set_is_offline: false,
    is_offline: false,
    set_is_online: false,
    is_online: false
  },
  is_all_data: true,
  is_active_price: false,
  limit: 10,
  page: 1,
  order: 'created_at',
  sort: 'ASC',
}
