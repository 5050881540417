// components/LoadingChart.js
import React from 'react'

const shimmerStyle = {
  background: 'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
  backgroundSize: '800px 104px',
  animation: 'shimmer 1.5s infinite linear',
}

const chartPlaceholderStyle = {
  width: '100%',
  height: '100%',
  backgroundColor: '#e0e0e0',
}

const keyframesStyle = `
  @keyframes shimmer {
    0% {
      background-position: -800px 0;
    }
    100% {
      background-position: 800px 0;
    }
  }
`

const LoadingChart = () => {
  return (
    <div className='card' style={{width: '100%', height: '300px', ...shimmerStyle}}>
      <style>{keyframesStyle}</style>
      <div className='card-body'>
        <div className='placeholder-glow'>
          <div style={chartPlaceholderStyle}></div>
        </div>
      </div>
    </div>
  )
}

export default LoadingChart
