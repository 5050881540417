import {toAbsoluteUrl} from '@/_metronic/helpers'
import {useQuery} from 'react-query'
import {getCmsById} from '../cms/core/_requests'
import parse from 'html-react-parser'

const PrivacyPolicyPage = () => {
  const privacyPolicyGuid = process.env.REACT_APP_PRIVACY_POLICY_GUID

  const {isFetching, data: response} = useQuery(
    `privacy-policy`,
    () => {
      return getCmsById(privacyPolicyGuid)
    },
    {cacheTime: 0, keepPreviousData: true}
  )

  return (
    <>
      <div className='container z-10'>
        <div className='row justify-content-center my-5 gap-5'>
          <div className='col-12 d-flex justify-content-center'>
            <img
              src={toAbsoluteUrl('/media/logos/logo-twothirds.png')}
              className='w-200px img-fluid'
            />
          </div>
          <div className='col-12 col-md-8 col-lg-6'>
            <div className='card py-4 px-5 shadow-wit-2 bg-illustration-order'>
              <div className='text-center'>
                <h1 className='fw-bolder mt-4'>Privacy Policy</h1>
              </div>
              <div className='row mt-2'>{parse(response?.response?.data?.content || '')}</div>
              <img
                src={toAbsoluteUrl('/media/illustrations/Ilustration_order.png')}
                className='w-200px img-fluid position-absolute backdrop-order top-5 z-9'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicyPage
