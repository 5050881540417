import { MenuComponent } from '@/_metronic/assets/ts/components'
import { KTIcon } from '@/_metronic/helpers'
import PropagateWrapper from '@/_metronic/partials/content/propagate/PropagateWrapper'
import { FilterDropdownCategory } from '@/app/modules/product-stock/product-stock-list/components/header/FilterDropdownCategory'
import { useEffect, useMemo, useState } from 'react'
import { useQueryRequest } from '../../../core/QueryRequestProvider'
import { useQueryResponse } from '../../../core/QueryResponseProvider'
import { DropdownSelect } from '@/_metronic/partials/content/dropdown-select/DropdownSelect'
import { FilterDropdownOutlet } from '@/app/modules/subscription/subscription-list/components/header/FilterDropdownOutlet'
import { useAuth } from '@/app/modules/auth'
import { initialFilterWalletHistory } from '../../../core/_models'
import { FilterDropdownCustomer } from './FilterDropdownCustomer'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import clsx from 'clsx'

const optionStatus = [
  {
    label: "Awaiting Payment",
    value: "Awaiting Payment"
  },
  {
    label: "Completed",
    value: "Completed"
  }
]

type DatePickerProps = {
  start: moment.Moment
  end: moment.Moment
}

const TopupHistoryListFilter = () => {
  const {updateState} = useQueryRequest()
  const [filterValue, setFilterValue] = useState(initialFilterWalletHistory.filter)
  const [category, setCategory] = useState<any>(null)
  const [outlet, setOutlet] = useState<any>(undefined)
  const [state, setState] = useState<DatePickerProps>({
    start: moment().subtract(29, 'days'),
    end: moment(),
  })
  const {isLoading} = useQueryResponse()
  
  const {currentUser} = useAuth()
  const userOutlet = currentUser?.detail_data?.outlet
  const hasOutlet = useMemo(() => {
    return Boolean(userOutlet?.outlet_id && userOutlet?.outlet_name)
  }, [currentUser, userOutlet])
  
  const daterangepickerButtonClass = 'bg-body btn-color-gray-700 btn-active-color-primary'

  const {start, end} = state
  const label = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY')

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  useEffect(() => {
    if (hasOutlet) {
      setOutlet({label: userOutlet?.outlet_name, value: userOutlet?.outlet_id})
      updateState({
        filter: {
          ...filterValue,
          // outlet_guid: userOutlet?.outlet_id,
          // set_outlet_guid: true,
        },
        page: 1,
        ...updateState,
      })
    }
  }, [hasOutlet])

  const resetData = () => {
    setFilterValue(initialFilterWalletHistory.filter)
    setCategory(null)
    setOutlet(null)
    updateState({filter: initialFilterWalletHistory.filter, page: 1})
  }

  const filterData = () => {
    updateState({
      filter: filterValue,
      page: 1,
      ...updateState,
    })
  }

  const handleCallback = (start: moment.Moment, end: moment.Moment) => {
    setState({start, end})
    setFilterValue((prevState) => ({
      ...prevState,
      set_param_date: true,
      param_from_date: start.add('hours', 7).toISOString(),
      param_end_date: end.add('hours', 7).toISOString(),
    }))
  }

  return (
    <>
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <PropagateWrapper>
            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold'>Transaction Number:</label>
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid'
                placeholder='Transaction Number'
                value={filterValue?.param_transaction_number}
                onChange={(e: any) =>
                  setFilterValue({
                    ...filterValue,
                    param_transaction_number: e.target.value,
                    set_param_transaction_number: e.target.value !== null,
                  })
                }
              />
            </div>
            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold'>Customer:</label>
              <FilterDropdownCustomer
                onValueChange={(newValue) => {
                  setOutlet(newValue)
                  setFilterValue({
                    ...filterValue,
                    param_customer_guid: newValue?.value,
                    set_param_customer_guid: Boolean(newValue?.value),
                  })
                }}
                currentValue={outlet}
                isDisabled={hasOutlet}
              />
            </div>
            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold'>Status:</label>
              <DropdownSelect
                options={optionStatus}
                value={optionStatus.find((item) => item.value === filterValue?.param_status)}
                onChange={(e) => setFilterValue({
                  ...filterValue,
                  set_param_status: Boolean(e.value !== undefined),
                  param_status: e.value
                })}
              />
            </div>
            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold'>Date:</label>
              <DateRangePicker
                initialSettings={{
                  startDate: state.start.toDate(),
                  endDate: state.end.toDate(),
                  ranges: {
                    Today: [moment().toDate(), moment().toDate()],
                    Yesterday: [
                      moment().subtract(1, 'days').toDate(),
                      moment().subtract(1, 'days').toDate(),
                    ],
                    'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                    'Last 30 Days': [
                      moment().subtract(29, 'days').toDate(),
                      moment().toDate(),
                    ],
                    'This Month': [
                      moment().startOf('month').toDate(),
                      moment().endOf('month').toDate(),
                    ],
                    'Last Month': [
                      moment().subtract(1, 'month').startOf('month').toDate(),
                      moment().subtract(1, 'month').endOf('month').toDate(),
                    ],
                  },
                }}
                onCallback={handleCallback}
              >
                <div
                  data-kt-daterangepicker='true'
                  data-kt-daterangepicker-opens='left'
                  className={clsx(
                    'btn btn-sm fw-bold  d-flex align-items-center px-4',
                    daterangepickerButtonClass
                  )}
                >
                  <div className='text-gray-600 fw-bold'>{label}</div>
                  <KTIcon iconName='calendar-8' className='fs-1 ms-2 me-0' />
                </div>
              </DateRangePicker>
            </div>
          </PropagateWrapper>

          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export { TopupHistoryListFilter }

