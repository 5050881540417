import { ProductModifierListSearchComponent } from "@/app/modules/product-modifier/product-modifier-list/components/header/ProductModifierListSearchComponent"
import { ProductModifierListToolbar } from "@/app/modules/product-modifier/product-modifier-list/components/header/ProductModifierListToolbar"


const ProductModifierListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <ProductModifierListSearchComponent />
      <div className='card-toolbar'>
        <ProductModifierListToolbar />
      </div>
    </div>
  )
}

export {ProductModifierListHeader}
