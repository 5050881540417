/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {
  useQueryResponseLoading,
  useQueryResponsePagination,
} from '../../../core/QueryResponseProvider'
import {useQueryRequest} from '../../../core/QueryRequestProvider'
import CustomPagination from '@/_metronic/partials/layout/pagination/CustomPagination'

const SubscriptionListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (page: number | undefined | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({page})
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <CustomPagination
            totalCount={pagination.totalCount}
            pageSize={pagination.pageSize}
            currentPage={pagination.page}
            onPageChange={updatePage}
          />
        </div>
      </div>
    </div>
  )
}

export {SubscriptionListPagination}
