import {axios} from '@/_metronic/helpers/network/Axios'

const MEDIA_API_URL = '/media-services'
const MEDIA_UPLOAD = '/bucket/upload'

const mediaUpload = (file: File) => {
  const body = new FormData()
  body.append('file', file)
  return axios
    .post(`${MEDIA_API_URL}${MEDIA_UPLOAD}`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((d: any) => d.data)
}

export {mediaUpload}
