import { KTCard, KTIcon } from '@/_metronic/helpers'
import { DropdownSelect } from '@/_metronic/partials/content/dropdown-select/DropdownSelect'
import { SingleDatePicker } from '@/_metronic/partials/content/single-datepicker/SingleDatePicker'
import { uniqueId, values } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedNumber, IntlProvider } from 'react-intl'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { DropdownOutlet } from './components/DropdownOutlet'
import { DropdownPic } from './components/DropdownPic'
import { DropdownRawMaterial } from './components/DropdownRawMaterial'
import { DropdownSupplier } from './components/DropdownSupplier'
import * as Yup from 'yup'
import { FieldArray, Form, Formik, FormikContext, FormikProvider, useFormik } from 'formik'
import moment from 'moment'
import { formatRupiah, removeDots } from '@/app/utils'
import { RowDetails } from './components/RowDetails'
import {
  createProductModifier,
  createProductModifierWithChild,
  updateProductModifierWithChild,
} from '../core/_requests'
import { RESP, WORKFLOW } from '@/_metronic/helpers/network/network-consts'
import { DetailResponseProvider, useDetailResponse } from '../core/DetailResponseProvider'
import { ProductModifierFormProvider, useProductProviderForm } from '../core/ProductModifierFormProvider'
import clsx from 'clsx'
import { useAuth } from '../../auth'
import { useSwal } from '@/app/core/SwalProvider'
import { toast } from 'react-toastify'
import { useDropzone } from 'react-dropzone'
import { mediaUpload } from '@/app/core/media/_requests'
import { CvSection } from './components/CvSection'
import { InputGroup } from '@/_metronic/partials/content/input-group/InputGroup'
import { Toggle } from '@/_metronic/partials/content/toggle/Toggle'
import { resolve } from 'path'

const productModifierInitialValues = {
  name: '',
  isActive: false,
  minimum_quantity: 0,
  maximum_quantity: 0,
  details: [
    {
      name: '',
      min_quantity: 0,
      max_quantity: 0,
      price: 0,
      sold_out: false,
      active: false
    }
  ]
}

const productModifierSchemes = Yup.object().shape({
  name: Yup.string().required('Nama tidak boleh kosong'),
  isActive: Yup.boolean().default(false),
  minimum_quantity: Yup.number().required("Min Quantity tidak boleh kosong"),
  maximum_quantity: Yup.number().required("Max Quantity tidak boleh kosong"),
  details: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Nama tidak boleh kosong"),
        min_quantity: Yup.number().required('Min Quantity tidak boleh kosong'),
        max_quantity: Yup.number().required('Max Quantity tidak boleh kosong'),
        price: Yup.number().required('Price tidak boleh kosong'),
        sold_out: Yup.boolean().default(false).required('isActive tidak boleh kosong'),
        active: Yup.boolean().default(false).required('isActive tidak boleh kosong'),
      })
    )
    .required(),
})

const ProductModifierEdit = () => {
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false)
  const [isApproved, setIsApproved] = useState<boolean>(false)
  const [valueRange, setValueRange] = useState<any>({minimum: 0, maximum: 0})
  const [isMaxQtyValid, setIsMaxQtyValid] = useState<any>([])

  const { id } = useParams()
  const { data } = useDetailResponse()
  const { toDelete } = useProductProviderForm()
  const { currentUser } = useAuth()
  const { swal } = useSwal()

  const navigate = useNavigate()
  const statusRef = useRef<string>('draft')

  const [files, setFiles] = useState([])

  const formik = useFormik({
    validationSchema: productModifierSchemes,
    initialValues: productModifierInitialValues,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: async (values: any) => {
      try {
        const body = {
          name: values.name,
          minimum_quantity: values.minimum_quantity,
          maximum_quantity: values.maximum_quantity,
          modifier_detail: values.details.map((item) => {
            return {
              guid: item.guid ? item.guid : undefined,
              name: item.name,
              images: item.images,
              description: item.description,
              sell_price: item.price.toString(),
              minimum_quantity: item.min_quantity,
              maximum_quantity: item.max_quantity,
              is_sold_out: item.sold_out,
              status: item.active,
              action: item.action
            }
          })
        }

        if(id) {
          const res = await updateProductModifierWithChild(id, body)
          if (res?.response?.code === RESP.SUCCESS) {
            toast.success('Product modifier berhasil diupdate')
            navigate('/master-data/product/modifier/list')
          }

          return
        }

        body.modifier_detail = body.modifier_detail.filter((item) => item.action !== "delete")
        const res = await createProductModifierWithChild(body)
        if (res?.response?.code === RESP.SUCCESS) {
          toast.success('Product modifier berhasil dibuat')
          navigate('/master-data/product/modifier/list')
        }
      } catch (error) {
        console.error(error)
      }
    },
  })
  const { values, handleChange, errors, setFieldValue, isValid, handleSubmit, touched, setValues } =
    formik

  const hasOutlet = useMemo(
    () =>
      Boolean(
        currentUser?.detail_data?.outlet?.outlet_id && currentUser?.detail_data?.outlet?.outlet_name
      ),
    [currentUser]
  )

  useEffect(() => {
    if (!isReadOnly && hasOutlet) {
      const outlet = currentUser?.detail_data?.outlet
      setFieldValue('outlet', { label: outlet?.outlet_name, value: outlet?.outlet_id })
      // setOutletReadOnly(true)
    }
  }, [hasOutlet, isReadOnly])

  useEffect(() => {
    if (window?.location?.pathname?.includes('/detail')) {
      setIsReadOnly(true)
    }
  }, [window?.location])

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.length) {
      processFile(acceptedFiles)
    }
  }, [])

  const { getInputProps } = useDropzone({ onDrop, multiple: false, disabled: isReadOnly })

  const processFile = async (acceptedFiles) => {
    const arrFiles: any = await Promise.all(
      acceptedFiles.map(async (file) => {
        try {
          const res = await mediaUpload(file)
          if (res?.message?.toLowerCase() === RESP.MSG_SUCCESS) {
            return res?.data?.url
          }

          return ''
        } catch (error) {
          return ''
        }
      })
    )
    setFiles(arrFiles)
    setFieldValue('upload_file_url', arrFiles?.[0])
  }

  useEffect(() => {
    if (data) {
      const detailData: any = {
        name: data.name,
        isActive: data.is_active,
        minimum_quantity: data.minimum_quantity,
        maximum_quantity: data.maximum_quantity,
        details: data.modifier_detail?.map((item) => {
          return {
            guid: item.modifier_detail_id,
            name: item.name,
            images: item.images,
            description: item.description,
            min_quantity: item.minimum_quantity,
            max_quantity: item.maximum_quantity,
            price: item.sell_price,
            sold_out: item.is_sold_out,
            active: item.status,
            action: 'update'
          }
        })
      }

      setValues(detailData)
      setValueRange({
        minimum: data.minimum_quantity,
        maximum: data.maximum_quantity
      })
    }
  }, [data])

  useEffect(() => {
    console.log(isMaxQtyValid)
  }, [isMaxQtyValid])

  return (
    <>
      <FormikProvider value={formik}>
        <div className='card card-flush'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>Modifier</h2>
            </div>
          </div>
          <div className='card-body pt-0'>
            <div className='row mt-3'>
              <div className='col-4'>
                <InputGroup
                  label='Modifier Name'
                  isValid={!(Boolean(errors?.name) && Boolean(touched.name))}
                  errorMessage={errors?.name as string}
                  value={values.name}
                  onChange={formik.handleChange('name')}
                  onBlur={formik.handleBlur('name')}
                  disabled={isReadOnly}
                />
              </div>
              <div className='col-4'>
                <InputGroup
                  label='Minimum Quantity'
                  isValid={!(values.minimum_quantity < values.details.reduce((acc, val) => acc + val.min_quantity, 0))}
                  errorMessage={'Total minimum quantity below must be lower than this minimum quantity'}
                  name='minimum_quantity'
                  type='number'
                  value={values.minimum_quantity}
                  // onChange={handleChange('conversion')}
                  onChange={(e) => {
                    setFieldValue("minimum_quantity", parseInt(e.target.value))
                    setValueRange((prevState) => ({
                      ...prevState,
                      minimum: e.target.value
                    }))
                  }}
                  readOnly={isReadOnly}
                />
              </div>
              <div className='col-4'>
                <InputGroup
                  label='Maximum Quantity'
                  name='maximum_quantity'
                  type='number'
                  value={values.maximum_quantity}
                  // onChange={handleChange('conversion')}
                  onChange={(e) => {
                    setFieldValue("maximum_quantity", parseInt(e.target.value))
                    setValueRange((prevState) => ({
                      ...prevState,
                      maximum: e.target.value
                    }))
                  }}
                  readOnly={isReadOnly}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card card-flush mt-5'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>Detail</h2>
            </div>
          </div>
          <FieldArray
            name='details'
            render={(arrayHelpers) => (
              <div className='card-body pt-0'>
                <div className='table-responsive'>
                  <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                  >
                    <thead>
                      <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        <th>No</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Minimum Quantity</th>
                        <th>Maximum Quantity</th>
                        <th>Price</th>
                        <th>Sold out</th>
                        <th>Active</th>
                        {!isReadOnly && <th></th>}
                      </tr>
                    </thead>
                    <tbody className='text-dark mb-1 fs-6'>
                      <>
                        <RowDetails
                          isReadOnly={isReadOnly}
                          arrayHelpers={arrayHelpers}
                          valueRange={valueRange}
                          setIsMaxQtyValid={setIsMaxQtyValid}
                          isMaxQtyValid={isMaxQtyValid}
                        />
                      </>
                    </tbody>
                  </table>
                </div>
                {!isReadOnly && (
                  <button
                    className='btn btn-primary mt-3'
                    type='button'
                    onClick={() => {
                      arrayHelpers.push({
                        name: '',
                        min_quantity: 0,
                        max_quantity: 0,
                        price: 0,
                        sold_out: false,
                        active: false,
                        action: 'create'
                      })
                    }}
                  >
                    Add Modifier Detail
                  </button>
                )}
              </div>
            )}
          />
        </div>
        <div className='d-flex justify-content-end gap-3 mt-5'>
          <Link to='/master-data/product/modifier/list'>
            <a href='#' className='btn btn-light me-5'>
              {isReadOnly ? 'Back' : 'Cancel'}
            </a>
          </Link>
          {/* {isApproved && (
            <button
              className='btn btn-primary'
              disabled={!isValid}
              onClick={() => {
                // statusRef.current = 'waiting for approval'
                // handleSubmit()
                // handleProcessOrder()
              }}
            >
              Process Order
            </button>
          )} */}
          {/* {isInProcess && (
            <button
              className='btn btn-light-primary border border-primary'
              disabled={!isValid}
              onClick={() => {
                swal
                  ?.fire({
                    html: 'Apakah anda yakin ingin close PO ini?',
                    icon: 'warning',
                    buttonsStyling: false,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, batal',
                    showCancelButton: true,
                    customClass: {
                      confirmButton: 'btn fw-bold btn-danger',
                      cancelButton: 'btn fw-bold btn-active-light-primary',
                    },
                  })
                  .then(async (value) => {
                    if (value.isConfirmed) {
                      statusRef.current = 'finished'
                      handleSubmit()
                    }
                  })
              }}
            >
              Finish
            </button>
          )} */}
          {!isReadOnly && (
            <>
              <button
                className='btn btn-primary'
                disabled={!isValid || isMaxQtyValid.find((item) => !item.valid) || (values.minimum_quantity < values.details.reduce((acc, val) => acc + val.min_quantity, 0))}
                onClick={() => {
                  statusRef.current = 'waiting for approval'
                  handleSubmit()
                }}
              >
                Save
              </button>
            </>
          )}
        </div>
      </FormikProvider>
    </>
  )
}

const ProductModifierEditWrapper = () => {
  return (
    <>
      <DetailResponseProvider>
        <ProductModifierFormProvider>
          <ProductModifierEdit />
        </ProductModifierFormProvider>
      </DetailResponseProvider>
    </>
  )
}

export { ProductModifierEditWrapper }
