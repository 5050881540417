import { KTIcon, isNotEmpty } from '@/_metronic/helpers'
import { DropdownAsyncSelect } from '@/_metronic/partials/content/dropdown-select/DropdownAsyncSelect'
import { DropdownSelect } from '@/_metronic/partials/content/dropdown-select/DropdownSelect'
import { getEmployeeDropdown } from '@/app/core/employee/_requests'
import { getOutletDropdown } from '@/app/core/master-data/outlet/_requests'
import { initialFilter } from '@/app/modules/human-resources/employee/core/_models'
import { initialFilterOutlet } from '@/app/modules/outlet/core/_models'
import { initialFilter as initialFilterProduct } from '@/app/modules/product/core/_models'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useListView } from '../../../core/ListViewProvider'
import { useQueryResponse, useQueryResponseData } from '../../../core/QueryResponseProvider'
import { DropdownSelectAsyncPaginate } from '@/_metronic/partials/content/dropdown-select/DropdownSelectAsyncPaginate'
import { forIn } from 'lodash'
import { Spinner } from 'react-bootstrap'
import { RESP } from '@/_metronic/helpers/network/network-consts'
import { toast } from 'react-toastify'
import { getProducts } from '@/app/modules/product/core/_requests'
import {InputGroup} from '@/_metronic/partials/content/input-group/InputGroup'
import { Toggle } from '@/_metronic/partials/content/toggle/Toggle'
import { useDetailResponse } from '@/app/modules/product-price/core/DetailResponseProvider'
import { useProductPriceResponseData } from '@/app/modules/product-price/core/ProductPriceResponseProvider'
import { useProductPrice } from '@/app/modules/product-price/core/PriceProvider'
import { formatRupiah, removeDots } from '@/app/utils'
import { SubscriptionHistory } from '../../../core/_models'
import { useDetailResponseData } from '@/app/modules/kpi-home/core/DetailResponseProvider'

type Props = {
  isLoading?: boolean
  data?: SubscriptionHistory
}

const initialListPrice = {
  product_price_id: '',
  product_variant_sku: '',
  outlet_id: '',
  hpp: 0,
  price: 0,
  discount: 0,
  discount_type: 'decimal',
  minimal_stock: 0,
  sales_type: {
    label: '',
    value: ''
  },
  base_price: 0,
  discount_outlet: 0,
  discount_platform: 0,
  additional_amount: 0,
  sell_price: 0,
  is_active: false,
  action: 'create'
}

const SubscriptionHistoryModalForm: React.FC<Props> = ({data}) => {
  const { id } = useParams()

  return (
    <form className='form d-flex flex-column flex-lg-row'>
      <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
        <div className='card card-flush'>
          <div className='card-body pt-0'>
            <div className='w-100'>
              <div className='row mt-1'>
                <div className='col-6'>
                  <InputGroup
                    label='Transaction Number'
                    value={data?.transaction_number}
                    disabled={true}
                    required={false}
                  />
                </div>
                <div className='col-6'>
                  <InputGroup
                    label='Name'
                    value={data?.subscription?.name}
                    disabled={true}
                    required={false}
                  />
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col-4'>
                  <InputGroup
                    label='Outlet'
                    value={data?.outlet?.outlet_name}
                    disabled={true}
                    required={false}
                  />
                </div>
                <div className='col-4'>
                  <InputGroup
                    label='Expired Days'
                    value={data?.expired_days}
                    disabled={true}
                    required={false}
                  />
                </div>
                <div className='col-4'>
                  <InputGroup
                    label='Expired Date'
                    value={data?.expired_date}
                    disabled={true}
                    required={false}
                  />
                </div>
              </div>
            </div>
            <table className='w-100 mt-5'>
              <thead>
                <th className='px-4 w-60px'>
                  <label className='fw-bold'>No</label>
                </th>
                <th className='px-4'>
                  <label className='fw-bold'>Product</label>
                </th>
                <th className='px-4'>
                  <label className='fw-bold'>SKU</label>
                </th>
                <th className='px-4'>
                  <label className='fw-bold'>Quantity</label>
                </th>
                <th className='px-4'>
                  <label className='fw-bold'>Outstanding</label>
                </th>
              </thead>
              <tbody>
              {data?.items?.map((form, ix) => (
                <tr
                  key={ix}
                  data-repeater-item=''
                  className='border-bottom'
                >
                  <td className='p-4'>
                    {ix + 1}
                  </td>
                  <td className='p-4'>
                    {form.detail_product?.product_name}
                  </td>
                  <td className='p-4'>
                    {form.detail_product?.product_variant?.[0]?.sku}
                  </td>
                  <td className='p-4'>
                    {form.qty}
                  </td>
                  <td className='p-4'>
                    <span className={`${form.outstanding === 0 && 'text-danger'} text-success fw-bold`}>{form.outstanding}</span>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </form>
  )
}

export { SubscriptionHistoryModalForm }
