import {FilterOutlet} from '@/app/modules/outlet/core/_models'
import {getOutlets} from '@/app/modules/outlet/core/_requests'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {
  DropdownSelectAsyncPaginate,
  OptionType,
} from '@/_metronic/partials/content/dropdown-select/DropdownSelectAsyncPaginate'
import {FC, useEffect, useState} from 'react'
import {MultiValue} from 'react-select'
import { FilterProductPrice, initialFilterProductPrice } from '@/app/modules/product-price/core/_models'
import { getProductPrices } from '@/app/modules/product-price/core/_requests'
import { useFormikContext } from 'formik'

type Props = {
  currentValue?: OptionType | MultiValue<OptionType> | null
  onValueChange?: any
  className?: string
  isDisabled?: boolean
  label?: string
}

const DropdownProductPrice: FC<Props> = ({currentValue, onValueChange, isDisabled, label}) => {
  const [_, onChange] = useState<OptionType | MultiValue<OptionType> | null>(currentValue || null)
  const {values} = useFormikContext<any>()

  const loadOptionsProductPrice = async (search: string, page: number) => {
    try {
      const body = {
        ...initialFilterProductPrice,
        filter: {
          ...initialFilterProductPrice.filter,
          set_name: Boolean(search),
          name: search,
          set_is_active: true,
          is_active: true,
          set_outlet_id: values.outlet ? true : false,
          outlet_id: values.outlet ? values.outlet.value : "",
          set_sales_type_id: values.sales_type ? true : false,
          sales_type_id_value: values.sales_type ? values.sales_type.value : ""
        },
      } as BaseRequest<FilterProductPrice>

      const resp = await getProductPrices(body)
      if (resp?.response?.data) {
        const {data} = resp.response
        return {
          options: data?.map((x) => ({label: x.product?.name, value: x.id, sell_price: x.sell_price, sku: x.product_variant_sku})),
          hasMore: page < (resp?.response?.total_page || 0),
        }
      }

      return {options: [], hasMore: false, additional: page}
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      }
    }
  }

  const loadOptions: any = async (q, _, additional) => {
    console.log(values)
    const {page} = additional!
    const {options: responseOptions, hasMore} = await loadOptionsProductPrice(q, page)

    return {
      options: responseOptions,
      hasMore,
      additional: {
        page: page + 1,
      },
    }
  }

  useEffect(() => {
    onValueChange(currentValue)
  }, [currentValue])

  return (
    <div className='col-12 mb-10'>
      <label htmlFor='category-select' className='form-label'>
        {label}
      </label>
      <DropdownSelectAsyncPaginate
        key={'product'}
        placeholder='Select Product'
        loadOptions={loadOptions}
        onChange={(newValues) => onValueChange(newValues)}
        value={currentValue}
        className='min-w-300px'
        isDisabled={isDisabled}
        isClearable
        useBody
        cacheUniqs={[values]}
      />
    </div>
  )
}

export {DropdownProductPrice}
