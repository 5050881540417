import {AxiosResponse} from 'axios'
import {axios} from '@/_metronic/helpers/network/Axios'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import { FilterWalletCustomer, FilterWalletHistory, WalletCustomerQueryResponse, WalletHistory, WalletHistoryQueryResponse } from './_models'

const CRM_API_URL = '/crm-services'
const WALLET_API_URL = `${CRM_API_URL}/wallet`
const WALLET_CUSTOMER_LIST_API_URL = `${WALLET_API_URL}/list-by-customer`
const WALLET_HISTORY_API_URL = `${WALLET_API_URL}/topup/transaction`

//#region SUBSCRIPTION HISTORY

const getWalletHistories = (body: BaseRequest<FilterWalletHistory>): Promise<WalletHistoryQueryResponse> => {
  return axios
    .post(`${WALLET_HISTORY_API_URL}/list`, body)
    .then((d: AxiosResponse<WalletHistoryQueryResponse>) => d.data)
}

const getWalletHistoryById = (id: string | undefined): Promise<BaseResponse<WalletHistory>> => {
  return axios
    .get(`${WALLET_HISTORY_API_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<WalletHistory>>) => d.data)
}

//#endregion

//#region SUBSCRIPTION CUSTOMER

const getWalletCustomers = (body: BaseRequest<FilterWalletCustomer>): Promise<WalletCustomerQueryResponse> => {
  return axios
    .post(`${WALLET_CUSTOMER_LIST_API_URL}`, body)
    .then((d: AxiosResponse<WalletCustomerQueryResponse>) => d.data)
}

//#endregion

export {
  getWalletHistories,
  getWalletHistoryById,
  getWalletCustomers
}
