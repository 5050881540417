import {isNotEmpty, QUERIES, WithChildren} from '@/_metronic/helpers'
import { ProductModifier } from '@/app/modules/product-modifier/core/_models'
import { getProductModifierById } from '@/app/modules/product-modifier/core/_requests'
import { Subscription } from '@/app/modules/subscription/core/_models'
import { getSubscriptionById } from '@/app/modules/subscription/core/_requests'
import {FC, createContext, useContext, useState} from 'react'
import {useQuery} from 'react-query'
import {useParams} from 'react-router-dom'
import { SubscriptionHistory } from './_models'
import { getSubscriptionHistoryById } from './_requests'

type DetailResponseContextProps = {
  data?: SubscriptionHistory
  isFetching: boolean
  refetch: () => void
  file: any
  fileUrl: string | undefined
  setFile: (file: any) => void
  setFileUrl: (file: any) => void
  uploadLoading: boolean
  setUploadLoading: (loading: boolean) => void
  isReadOnly: boolean
  setIsReadOnly: (readOnly: boolean) => void
}

const DetailResponseContext = createContext<DetailResponseContextProps>({
  data: undefined,
  isFetching: false,
  refetch: () => {},
  file: null,
  fileUrl: undefined,
  setFile: () => {},
  setFileUrl: () => {},
  uploadLoading: false,
  setUploadLoading: () => {},
  isReadOnly: false,
  setIsReadOnly: () => {},
})

const DetailResponseProvider: FC<WithChildren> = ({children}) => {
  const {id} = useParams()
  const enabledQuery: boolean = isNotEmpty(id)

  const [file, setFile] = useState<any>(null)
  const [uploadLoading, setUploadLoading] = useState<boolean>(false)
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined)
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false)

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.SUBSCRIPTION_HISTORY}-${id}`,
    () => {
      return getSubscriptionHistoryById(id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <DetailResponseContext.Provider
      value={{
        data: response?.response?.data,
        isFetching,
        refetch,
        file,
        setFile,
        fileUrl,
        setFileUrl,
        uploadLoading,
        setUploadLoading,
        isReadOnly,
        setIsReadOnly,
      }}
    >
      {children}
    </DetailResponseContext.Provider>
  )
}

const useDetailResponse = () => useContext(DetailResponseContext)

export {DetailResponseProvider, useDetailResponse}
