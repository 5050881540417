import { BaseRequest } from '@/_metronic/helpers/crud-helper/base-request'
import { BaseResponse } from '@/_metronic/helpers/crud-helper/base-list-response'

export interface Customer {
  city?: string
  guid?: string
  email?: string
  gender?: string
  status?: string
  province?: string
  full_name?: string
  outlet_data?: OutletData
  outlet_guid?: string
  main_address?: string
  phone_number?: string
  date_of_birth?: string
  profile_picture?: string
  additional_address?: AdditionalAddress[]
  customer_type_data?: CustomerTypeData
  customer_type_guid?: string
}

export interface AdditionalAddress {
  address: string
}

export interface CustomerTypeData {
  guid?: string
  name?: string
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
}

export interface OutletData {
  area?: string
  guid?: string
  name?: string
  email?: string
  address?: string
  city_id?: string
  brand_id?: string
  group_id?: string
  city_name?: string
  image_url?: null
  region_id?: string
  brand_name?: string
  created_at?: Date
  created_by?: string
  group_name?: string
  updated_at?: Date
  updated_by?: string
  url_tiktok?: string
  description?: string
  outlet_code?: string
  region_name?: string
  url_website?: string
  phone_number?: string
  url_instagram?: string
}

export interface FilterCustomer {
  set_outlet_guid?: boolean
  outlet_guid?: any[]
  set_customer_type_guid?: boolean
  customer_type_guid?: any[]
  set_full_name?: boolean
  full_name?: string
  set_phone_number?: boolean
  phone_number?: string
  set_email?: boolean
  email?: string
  set_gender?: boolean
  gender?: any[]
  set_date_of_birth?: boolean
  start_date?: string
  end_date?: string
  set_status?: boolean
  status?: any[]
}

export type CustomerQueryResponse = BaseResponse<Array<Customer>>

export const initialFilter: BaseRequest<FilterCustomer> = {
  filter: {
    set_outlet_guid: false,
    outlet_guid: [], // ARRAY STRING OUTLET GUID
    set_customer_type_guid: false,
    customer_type_guid: [], // ARRAY STRING CUSTOMER TYPE GUID
    set_full_name: false,
    full_name: '',
    set_phone_number: false,
    phone_number: '',
    set_email: false,
    email: '',
    set_gender: false,
    gender: [], // ARRAY ENUM GENDER: Tidak Spesifik, Laki-laki, Perempuan
    set_date_of_birth: false,
    start_date: '', // FORMAT yyyy-mm-dd
    end_date: '', // FORMAT yyyy-mm-dd
    set_status: false,
    status: [], // ARRAY STATUS Active, Expired
  },
  limit: 10,
  page: 1,
  order: 'created_at',
  sort: 'DESC',
}

export const initialCustomer: Customer = {
  guid: '',
  outlet_guid: '',
  outlet_data: null!,
  customer_type_guid: '62574c19-c2aa-41c7-886d-83264aed70cf',
  customer_type_data: null!,
  full_name: 'Guest',
  phone_number: 'guest-phone-number-2',
  email: 'guest-email-1',
  profile_picture: null!,
  date_of_birth: '',
  gender: 'Laki-laki',
  main_address: null!,
  additional_address: null!,
  province: '',
  city: '',
  status: 'Active',
}

export const initialCustomerHistory = [
  {
    guid: '',
    tanggal_daftar: '1 January 2024',
    tanggal_berakhir: '6 Juni 2024',
    membership_status: 'Gold Member',
    billing_information: 'Information',
  },
  {
    guid: '',
    tanggal_daftar: '1 January 2024',
    tanggal_berakhir: '31 December 2026',
    membership_status: 'Exclusive Member',
    billing_information: 'Information',
  },
]
