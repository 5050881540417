import {createContext, FC, useContext, useState} from 'react'
import {WithChildren} from '@/_metronic/helpers'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import { FilterWalletCustomer, initialFilterWalletCustomer } from './_models'

type WalletCustomerQueryRequestContextProps = {
  state: BaseRequest<FilterWalletCustomer>
  updateState: (updates: Partial<BaseRequest<FilterWalletCustomer>>) => void
}

const initialQueryRequest = {
  state: initialFilterWalletCustomer,
  updateState: () => {},
}

const QueryRequestContext = createContext<WalletCustomerQueryRequestContextProps>(initialQueryRequest)

const CustomerRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<BaseRequest<FilterWalletCustomer>>(initialQueryRequest.state)

  const updateState = (updates: Partial<BaseRequest<FilterWalletCustomer>>) => {
    const updatedState = {...state, ...updates} as BaseRequest<FilterWalletCustomer>
    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useCustomerRequest = () => useContext(QueryRequestContext)
export {CustomerRequestProvider, useCustomerRequest}
