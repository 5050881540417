import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { ProductModifierEditWrapper } from '@/app/modules/product-modifier/product-modifier-edit/ProductModifierEdit'
import { SubscriptionListWrapper } from '@/app/modules/subscription/subscription-list/SubscriptionList'
import { SubscriptionDetailWrapper } from '@/app/modules/subscription/subscription-detail/SubscriptionDetail'
import { TopupHistoryListWrapper } from './TopupHistoryList'

const productsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Wallet',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TopupHistoryPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/topup/history'
          element={
            <>
              <PageTitle breadcrumbs={productsBreadcrumbs}>Wallet History</PageTitle>
              <TopupHistoryListWrapper />
            </>
          }
        />
        <Route index element={<Navigate to='/topup/history' />} />
      </Route>
    </Routes>
  )
}

export default TopupHistoryPage
