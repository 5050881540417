import {BarChart} from '@/_metronic/partials/content/chart/BarChart'
import {useEffect, useState} from 'react'
import {TopEmployees} from './components/TopEmployee'
import {TopTreatment} from './components/TopTreatment'
import {CategoryAnalysisPieChart} from './components/chart/CategoryAnalysisPieChart'
import {TreatmentAnalysisPieChart} from './components/chart/TreatmentAnalysisPieChart'
import {FilterReportTrendAnalysisModal} from './components/filter/FilterReportTrendAnalysisModal'
import {useFilterReportTrendAnalysis} from './core/FilterReportTrendAnalysisProvider'
import {useQueryResponseData} from './core/QueryResponseProvider'

const TrendAnalysisOverview = () => {
  const data = useQueryResponseData()
  const [arrayOfSeriesSales, setArrayOfSeriesSales] = useState<number[]>([])
  const [arrayOfLabelsSales, setArrayOfLabelsSales] = useState<string[]>([])
  const [arrayOfSeriesTransaction, setArrayOfSeriesTransaction] = useState<number[]>([])
  const [arrayOfLabelsTransaction, setArrayOfLabelsTransaction] = useState<string[]>([])
  const [arrayOfSeriesTreatment, setArrayOfSeriesTreatment] = useState<number[]>([])
  const [arrayOfLabelsTreatment, setArrayOfLabelsTreatment] = useState<string[]>([])
  const {closeModal, showFilterReportTrendAnalysisModal} = useFilterReportTrendAnalysis()

  useEffect(() => {
    if (data) {
      setArrayOfSeriesSales([])
      setArrayOfLabelsSales([])
      setArrayOfSeriesTransaction([])
      setArrayOfLabelsTransaction([])
      setArrayOfSeriesTreatment([])
      setArrayOfLabelsTreatment([])
      if (data?.trend_transaction !== undefined) {
        // Sales
        const arrayOfSeriesSalesString = data?.trend_transaction?.map((obj) =>
          obj.total_sales !== undefined ? obj.total_sales : 0
        )
        setArrayOfSeriesSales(arrayOfSeriesSalesString)

        const arrayOfLabelsSalesString = data?.trend_transaction?.map((obj) =>
          obj.month !== undefined ? obj.month : ''
        )
        setArrayOfLabelsSales(arrayOfLabelsSalesString)

        // Transaction
        const arrayOfSeriesTransactionString = data?.trend_transaction?.map((obj) =>
          obj.total_transaction !== undefined ? obj.total_transaction : 0
        )
        setArrayOfSeriesTransaction(arrayOfSeriesTransactionString)

        const arrayOfLabelsTransactionString = data?.trend_transaction?.map((obj) =>
          obj.month !== undefined ? obj.month : ''
        )
        setArrayOfLabelsTransaction(arrayOfLabelsTransactionString)
      }

      // Treatment
      if (data && data?.trend_product) {
        const arrayOfSeriesTreatmentString = data?.trend_product?.map((obj) =>
          obj.total_qty !== undefined ? obj.total_qty : 0
        )
        setArrayOfSeriesTreatment(arrayOfSeriesTreatmentString)

        const arrayOfLabelsTreatmentString = data?.trend_product?.map((obj) =>
          obj.month !== undefined ? obj.month : ''
        )
        setArrayOfLabelsTreatment(arrayOfLabelsTreatmentString)
      }
    }
  }, [data])

  return (
    <>
      <div className='mt-8 mb-7'>
        <div className='col-md-12 mb-12'>
          <BarChart
            className='shadow-wit'
            color='info'
            title='Revenue'
            subtitle=''
            height='400px'
            series={[
              {
                name: 'Transaction',
                data: arrayOfSeriesSales,
              },
            ]}
            categories={arrayOfLabelsSales}
            total={data && data.summary_trend?.sales ? data.summary_trend?.sales : 0}
            data={data?.trend_transaction?.map((x) => ({
              month: x.month,
              total_sales: x.total_sales,
            }))}
            headers={['Bulan', 'Total Revenue']}
            formatCurrency={['total_sales']}
            layout='horizontal'
          />
        </div>
        <div className='col-md-12 mb-12'>
          <BarChart
            className='shadow-wit'
            color='success'
            title='Transaction'
            subtitle=''
            height='400px'
            series={[
              {
                name: 'Transaction',
                data: arrayOfSeriesTransaction,
              },
            ]}
            categories={arrayOfLabelsTransaction}
            total={data && data.summary_trend?.transaction ? data.summary_trend?.transaction : 0}
            isCurrency={false}
            data={data?.trend_transaction?.map((x) => ({
              month: x.month,
              total_transaction: x.total_transaction,
            }))}
            headers={['Bulan', 'Total Transaction']}
            layout='horizontal'
          />
        </div>
        <div className='col-md-12 mb-12'>
          <BarChart
            className='shadow-wit'
            color='primary'
            title='Product'
            subtitle=''
            height='400px'
            series={[
              {
                name: 'Product',
                data: arrayOfSeriesTreatment,
              },
            ]}
            categories={arrayOfLabelsTreatment}
            total={data && data.summary_trend?.product ? data.summary_trend?.product : 0}
            isCurrency={false}
            data={data?.trend_product?.map((x) => ({
              month: x.month,
              total_qty: x.total_qty,
            }))}
            headers={['Bulan', 'Total Product']}
            layout='horizontal'
          />
        </div>
        <div className='row'>
          <div className='col-6'>
            <CategoryAnalysisPieChart
              className='shadow-wit mb-10'
              data={data && data.trend_category ? data?.trend_category : undefined}
            />
          </div>
          <div className='col-6'>
            <TreatmentAnalysisPieChart
              className='shadow-wit'
              data={data && data.trend_product_list ? data?.trend_product_list : undefined}
            />
          </div>
          {/* <div className='col-md-8'>
            <TopTreatment className='shadow-wit-2 mb-10' data={data?.top_five_product} />
            <TopEmployees className='shadow-wit-2' data={data?.top_five_therapist} />
          </div> */}
        </div>
        {/* <div className='row mt-15'>
          <div className='col-md-4'>
            <CategoryAnalysisPieChart className='shadow-wit' />
          </div>
          <div className='col-md-8'>
            <TopTreatment className='shadow-wit-2' data={data?.top_five_product} />
          </div>
        </div> */}
        {/* <div className='row mt-15'>
          <div className='col-md-4'>
            <TreatmentAnalysisPieChart className='shadow-wit' />
          </div>
          <div className='col-md-8'>
            <TopEmployees className='shadow-wit-2' data={data?.top_five_therapist} />
          </div>
        </div> */}
      </div>
      <FilterReportTrendAnalysisModal
        show={showFilterReportTrendAnalysisModal}
        closeModal={() => closeModal()}
      />
    </>
  )
}

export {TrendAnalysisOverview}
