import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import { Subscription } from '../../subscription/core/_models'

//#region SUBSCRIPTION HISTORY

export type SubscriptionHistory = {
  outlet?: SubscriptionHistoryOutlet
  customer?: SubscriptionHistoryCustomer
  rounding?: number
  total_qty?: number
  total_tax?: number
  created_at?: string
  created_by?: string
  grandtotal?: number
  subscription?: SubscriptionHistoryDetail
  total_service?: number
  total_discount?: number
  total_subtotal?: number
  total_payment_fee?: number
  rounding_formatted?: string
  total_tax_formatted?: string
  grandtotal_formatted?: string
  total_service_formatted?: string
  total_discount_formatted?: string
  total_subtotal_formatted?: string
  total_payment_fee_formatted?: string
  subscription_transaction_guid?: string
  subscription_transaction_number?: string
  transaction_number?: string
  subscription_transaction_status?: string
  expired_days?: number
  expired_date?: string
  items?: SubscriptionItems[]
  active?: boolean
}

export type SubscriptionItems = {
  guid?: string
  qty?: number
  outstanding?: 0
  detail_product?: SubscriptionItemDetailProduct
}

export type SubscriptionItemDetailProduct = {
  product_guid?: string
  product_name?: string
  product_category_id?: string
  product_category_name?: string
  product_images_url_thumbnail?: string
  product_variant?: SubscriptionItemDetailProductVariant[]
}

export type SubscriptionItemDetailProductVariant = {
  guid?: string
  sku?: string
  name?: string
}

type SubscriptionHistoryDetail = {
  subscription_guid?: string
  subscription_name?: string
  name?: string
}

type SubscriptionHistoryCustomer = {
  customer_guid?: string
  customer_name?: string | null
}

export type SubscriptionHistoryOutlet = {
  brand_guid?: string
  brand_name?: string
  group_guid?: string
  group_name?: string
  outlet_guid?: string
  outlet_name?: string
  sub_brand_guid?: string
  sub_brand_name?: string
}

export type FilterSubscriptionHistory = {
  set_outlet_guid?: boolean
  outlet_guid?: string
  set_customer_guid?: boolean
  customer_guid?: string
  set_subscription_guid?: boolean
  subscription_guid?: string
  set_subscription_transaction_number?: boolean
  subscription_transaction_number?: string
  set_subscription_transaction_status?: boolean
  subscription_transaction_status?: string[]
}

export type SubscriptionHistoryQueryResponse = BaseResponse<Array<SubscriptionHistory>>

export const initialSubscriptionHistory: SubscriptionHistory = {
  outlet: {
    brand_guid: "5f266f05-4206-4370-a95c-39ddf9ec1de4",
    brand_name: "Two Thrds",
    group_guid: "65f03b61-a613-4528-aa8a-881b8e118a4e",
    group_name: "TwoThrds",
    outlet_guid: "f0d8bb5d-ddd4-453a-896b-42875a634157",
    outlet_name: "Two Thrds Senayan",
    sub_brand_guid: "d9cc302f-8f13-4fac-a7e3-1d0cbd006a26",
    sub_brand_name: "Two Thrds Coffee"
  },
  customer: {
    customer_guid: "236c9dac-267b-437a-9450-7b84580956e0",
    customer_name: null,
  },
  rounding: 0,
  total_qty: 20,
  total_tax: 75000,
  created_at: '2024-06-20T14:08:22.725071',
  created_by: "236c9dac-267b-437a-9450-7b84580956e0",
  grandtotal: 825000,
  subscription: {
    subscription_guid: "739ad88f-3997-4130-af1b-e54577b402f7",
    subscription_name: "Two Thrds Subscription Hot Drink",
  },
  total_service: 0,
  total_discount: 0,
  total_subtotal: 750000,
  total_payment_fee: 0,
  rounding_formatted: "Rp 0",
  total_tax_formatted: "Rp  75.000",
  grandtotal_formatted: "Rp  825.000",
  total_service_formatted: "Rp 0",
  total_discount_formatted: "Rp 0",
  total_subtotal_formatted: "Rp  750.000",
  total_payment_fee_formatted: "Rp 0",
  subscription_transaction_guid: "3fa92282-94b7-4ed2-96a3-c4d345933c4d",
  subscription_transaction_number: "SUBS-20062024-00018",
  subscription_transaction_status: "Awaiting Payment"
}

export const initialFilter: BaseRequest<FilterSubscriptionHistory> = {
  filter: {
    set_outlet_guid: false,
    outlet_guid: '',
    set_customer_guid: false,
    customer_guid: '',
    set_subscription_guid: false,
    subscription_guid: '',
    set_subscription_transaction_number: false,
    subscription_transaction_number: '',
    set_subscription_transaction_status: false,
    subscription_transaction_status: [
      "Awaiting Payment",
      "Completed",
      "Partialy Paid"
    ]
  },
  limit: 10,
  page: 1,
  order: "created_at",
  sort: "DESC"
}

//#endregion

//#region SUBSCRIPTION CUSTOMER

export type SubscriptionCustomer = {
  customer_guid?: string
  customer_name?: string
  customer_phone?: string
  customer_email?: string
  subscription?: SubscriptionCustomerSubscription[] | null
}

export type SubscriptionCustomerSubscription = {
  active?: boolean
  outlet?: SubscriptionCustomerSubscriptionOutlet
  status?: string
  payment?: SubscriptionCustomerSubscriptionPayment
  brand_guid?: string
  group_guid?: string
  expired_date?: string
  expired_days?: number
  subscription?: Subscription
  sub_brand_guid?: string
  transaction_number?: string
  expired_date_formatted?: string
  subscription_transaction_guid?: string
}

type SubscriptionCustomerSubscriptionOutlet = {
  outlet_area?: string
  outlet_code?: string
  outlet_guid?: string
  outlet_name?: string
  outlet_address?: string
}

type SubscriptionCustomerSubscriptionPayment = {
  payment_method?: Payment
  payment_channel?: Payment
}

type Payment = {
  guid?: string
  name?: string
}

export type FilterSubscriptionCustomer = {
  set_outlet_guid?: boolean
  param_outlet_guid?: string
  set_customer_guid?: boolean
  param_customer_guid?: string
  set_customer_name?: boolean
  param_customer_name?: string
  set_subscription_transaction_status?: boolean
  subscription_transaction_status?: string[]
  set_subscription_active?: boolean
  subscription_active?: boolean
}

export type SubscriptionCustomerQueryResponse = BaseResponse<Array<SubscriptionCustomer>>

export const initialFilterSubscriptionCustomer: BaseRequest<FilterSubscriptionCustomer> = {
  filter: {
    set_outlet_guid: false,
    param_outlet_guid: '',
    set_customer_guid: false,
    param_customer_guid: '',
    set_customer_name: false,
    param_customer_name: '',
    set_subscription_transaction_status: false,
    subscription_transaction_status: [],
    set_subscription_active: true,
    subscription_active: true
  },
  limit: 10,
  page: 1,
  order: "created_at",
  sort: "DESC"
}
