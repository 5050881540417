import {BarChart} from '@/_metronic/partials/content/chart/BarChart'
import {useEffect, useState} from 'react'
import {AverageCard} from './components/AverageCard'

const RushHourOverview = ({data}) => {
  console.log({data})
  const [arrayOfSeriesTransaction, setArrayOfSeriesTransaction] = useState<number[]>([])
  const [arrayOfLabelsTransaction, setArrayOfLabelsTransaction] = useState<string[]>([])

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
  }

  useEffect(() => {
    if (data) {
      setArrayOfSeriesTransaction([])
      setArrayOfLabelsTransaction([])
      if (data?.bar_summary) {
        // Transaction
        const arrayOfSeriesTransactionString = data?.bar_summary?.map((obj) =>
          obj.total_transaction ? obj.total_transaction : 0
        )
        setArrayOfSeriesTransaction(arrayOfSeriesTransactionString)

        // const arrayOfLabelsTransactionString = data?.bar_summary?.map((obj, index) =>
        //   obj.times ? 'Top ' + index+1 + ' ' + obj.times : ''
        // )
        const arrayOfLabelsTransactionString = data?.bar_summary?.map((obj, index) => {
          const formattedIndex = index + 1
          return obj.times ? 'Top ' + formattedIndex + ' (' + obj.times + ')' : ''
        })
        setArrayOfLabelsTransaction(arrayOfLabelsTransactionString)
      }
    }
  }, [data])

  return (
    <>
      <div className='mt-8 mb-7'>
        <div className='row gap-5'>
          <div className='row gap-4 col-12 mb-4 pe-0'>
            <div className='col'>
              <AverageCard
                className='card-xl mb-xl-5 shadow-wit'
                color='white'
                title='Product'
                description={
                  data && data?.summary && data?.summary?.total_qtys
                    ? numberWithCommas(data?.summary?.total_qtys)
                    : numberWithCommas(0)
                }
                descriptionColor='info'
              />
            </div>
            <div className='col'>
              <AverageCard
                className='card-xl mb-xl-5 shadow-wit'
                color='white'
                title='Transaction'
                description={
                  data && data?.summary && data?.summary?.total_transaction
                    ? numberWithCommas(data?.summary?.total_transaction)
                    : numberWithCommas(0)
                }
                descriptionColor='warning'
              />
            </div>
            <div className='col pe-0'>
              <AverageCard
                className='card-xl mb-xl-5 shadow-wit'
                color='white'
                title='Revenue'
                description={
                  data && data?.summary && data?.summary?.total_sales
                    ? 'Rp. ' + numberWithCommas(data?.summary?.total_sales)
                    : 'Rp. ' + numberWithCommas(0)
                }
                descriptionColor='primary'
              />
            </div>
          </div>
          <div className='col-12'>
            <BarChart
              className='shadow-wit'
              color='primary'
              title='Transaction'
              data={data?.bar_summary}
              headers={['Time', 'Product', 'Revenue', 'Transaction']}
              subtitle=''
              height='260px'
              series={[
                {
                  name: 'Transaction',
                  data: arrayOfSeriesTransaction,
                },
              ]}
              categories={arrayOfLabelsTransaction}
              formatCurrency={['total_sales']}
              total={
                data && data?.summary && data?.summary?.total_transaction
                  ? data?.summary?.total_transaction
                  : 0
              }
              isCurrency={false}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default RushHourOverview
