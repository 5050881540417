import { KTIcon, isNotEmpty } from '@/_metronic/helpers'
import { DropdownAsyncSelect } from '@/_metronic/partials/content/dropdown-select/DropdownAsyncSelect'
import { DropdownSelect } from '@/_metronic/partials/content/dropdown-select/DropdownSelect'
import { getEmployeeDropdown } from '@/app/core/employee/_requests'
import { getOutletDropdown } from '@/app/core/master-data/outlet/_requests'
import { initialFilter } from '@/app/modules/human-resources/employee/core/_models'
import { initialFilterOutlet } from '@/app/modules/outlet/core/_models'
import { initialFilter as initialFilterProduct } from '@/app/modules/product/core/_models'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useListView } from '../../../core/ListViewProvider'
import { useQueryResponse } from '../../../core/QueryResponseProvider'
import { ProductModifier, ProductModifierRelation, initialFilterProductRelation } from '../../../core/_models'
import { DropdownSelectAsyncPaginate } from '@/_metronic/partials/content/dropdown-select/DropdownSelectAsyncPaginate'
import { forIn } from 'lodash'
import { Spinner } from 'react-bootstrap'
import { RESP } from '@/_metronic/helpers/network/network-consts'
import { toast } from 'react-toastify'
import { getProducts } from '@/app/modules/product/core/_requests'
import {InputGroup} from '@/_metronic/partials/content/input-group/InputGroup'
import { createProductModifierRelation, deleteProductModifierRelation, getProductModifierRelations, updateProductModifierRelation } from '../../../core/_requests'
import { Toggle } from '@/_metronic/partials/content/toggle/Toggle'
import { useProductModifierRelationResponse, useProductModifierRelationResponseData } from '../../../core/ProductModifierRelationResponseProvider'

type Props = {
  isLoading?: boolean
  data?: ProductModifier
}

const ProductRelationModalForm: React.FC<Props> = ({ data }) => {
  const { id } = useParams()
  const enabledQuery: boolean = isNotEmpty(id)
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false)
  const [modifierForm, setModifierForm] = useState<any>({
    modifier_guid: '',
    product: {
      label: '',
      value: ''
    },
    minimum_quantity: 0,
    maximum_quantity: 0
  })
  const { itemIdForUpdate, itemId, setItemId } = useListView()
  const { refetch } = useProductModifierRelationResponse()
  const { refetch: refetchTable } = useQueryResponse()
  const [isLoading, setLoading] = useState<boolean>(false)
  const productRelations = useProductModifierRelationResponseData()

  const loadProducts = async (inputValue: any, page: number) => {
    try {
      const requestBody = initialFilterProduct
      requestBody!.filter!.set_name = inputValue !== ''
      requestBody!.filter!.name = inputValue
      requestBody!.limit = 10
      requestBody!.page = page
      const data = await getProducts(requestBody)

      // Transform data to options format expected by react-select
      let options =
        data.response?.data?.map((item) => ({
          value: item.id || '',
          label: item.name || '',
          product_guid: item.id,
          image: item.image_thumbnail_url,
          code: item.code_product,
          name: item.name,
          category: item.category?.name,
          description: item.description
        })) || []

      return {
        options,
        hasMore: options.length > 9 && page < (data?.response?.total_page || 1),
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.error(error)
      return {
        options: [],
        hasMore: false,
      }
    }
  }

  const loadOptionsProduct: any = async (q, _, additional) => {
    const { page } = additional!
    const { options: responseOptions, hasMore } = await loadProducts(q, page)

    const filteredProduct = responseOptions.filter((itemParent) => !Boolean(productRelations.find(item =>
      itemParent.value === item.product?.id
    )))

    return {
      options: filteredProduct,
      hasMore,
      additional: {
        page: page + 1,
      },
    }
  }

  const handleRemoveItem = async (id?: string) => {
    if (id) {
      try {
        const res = await deleteProductModifierRelation(id)
        if (res.response?.code === RESP.SUCCESS) {
          toast.success("Modifier Product Relation successfully deleted")
          refetch()
          refetchTable()
        } else {
          toast.error(res.response?.message_en)
        }
      } catch (error) {
        toast.error("There's problem for this request")
      }
      
    }
  }

  const handleAddItem = async () => {
    setLoading(true)
    console.log(modifierForm)
    try {
      const body = {
        modifier_guid: modifierForm.modifier_guid,
        product_guid: modifierForm.product.value,
        minimum_quantity: parseInt(modifierForm.minimum_quantity),
        maximum_quantity: parseInt(modifierForm.maximum_quantity)
      }
      const res = await createProductModifierRelation(body)
      if (res.response === RESP.MSG_SUCCESS_CAPITALIZE) {
        toast.success("Modifier Product Relation successfully added")
        refetch()
        refetchTable()
        setModifierForm((prevState) => ({
          ...prevState,
          product: {
            label: '',
            value: ''
          }
        }))
      }
    } catch (error) {
      toast.error("There's problem for this request")
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateItem = (data: any) => async (e: any) => {
    setLoading(true)
    try {
      const body = {
        modifier_guid: data.modifier.id,
        product_guid: data.product.id,
        minimum_quantity: parseInt(data.minimum_quantity),
        maximum_quantity: parseInt(data.maximum_quantity),
        status: e.target.checked
      }
      const res = await updateProductModifierRelation(data.id, body)
      if (res.response === RESP.MSG_SUCCESS_CAPITALIZE) {
        toast.success("Modifier Product Relation successfully updated")
        refetch()
      }
    } catch (error) {
      toast.error("There's problem for this request")
    } finally {
      setLoading(false)
    }
  }

  const handleChangeForm = (field: string) => (e: any) => {
    if (field === "product") {
      setModifierForm((prevState) => ({
        ...prevState,
        [field]: {
          label: e.name,
          value: e.product_guid
        }
      }))
    } else {
      setModifierForm((prevState) => ({
        ...prevState,
        [field]: e.target.value
      }))
    }
  }

  useEffect(() => {
    if (data) {
      // loadListModifierProduct()
      setModifierForm((prevState) => ({
        ...prevState,
        modifier_guid: data?.id,
        minimum_quantity: data?.minimum_quantity,
        maximum_quantity: data?.maximum_quantity
      }))
    }
  }, [data])

  useEffect(() => {
    setIsReadOnly(itemIdForUpdate === 1)
  }, [])

  return (
    <form className='form d-flex flex-column flex-lg-row'>
      <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
          style={{ minHeight: '60vh', maxHeight: '60vh' }}
        >
          <div className='row'>
            <div className='col-12'>
              <div className='card card-flush'>
                <div className='card-body pt-0 mt-7'>
                  <div className='row'>
                    {
                      itemIdForUpdate !== 1 && (
                        <div className='col-3'>
                          <label className='form-label'>Select Product</label>
                          <DropdownSelectAsyncPaginate
                            loadOptions={loadOptionsProduct}
                            placeholder="Select product"
                            isDisabled={enabledQuery || isReadOnly}
                            value={modifierForm?.product.value !== "" ? modifierForm?.product : null}
                            onChange={handleChangeForm("product")}
                            cacheUniqs={productRelations}
                          />
                        </div>
                      )
                    }
                    <div className='d-flex col-auto justify-content-center align-items-end'>
                      {!isReadOnly && (
                        <button
                          className='btn btn-primary align-items-center'
                          type='button'
                          disabled={isLoading}
                          onClick={() => handleAddItem()}
                        >
                          {isLoading ? (
                            <Spinner animation='border' color='white' size='sm' />
                          ) : 'Add'}
                        </button>
                      )}
                    </div>
                    <div className='col-12 mt-9'>
                      <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer gs-0 gy-5'>
                          {/* begin::Table head */}
                          <thead>
                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                              <th className='min-w-50px columnheader'>No</th>
                              <th className='min-w-150px columnheader'>Product</th>
                              <th className='min-w-125px columnheader'>Active</th>
                              {!isReadOnly && <th className='min-w-40px columnheader'>Action</th>}
                            </tr>
                          </thead>
                          {/* end::Table head */}
                          {/* begin::Table body */}
                          <tbody>
                            {!isLoading ? productRelations?.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <label>{index + 1}</label>
                                </td>
                                <td>
                                  <label>{item.product?.name}</label>
                                </td>
                                <td>
                                  <label>
                                    <Toggle
                                      // label="Is Active"
                                      id={`isActive`}
                                      checked={item.status}
                                      onChange={handleUpdateItem(item)}
                                      disabled={isReadOnly}
                                    />
                                  </label>
                                </td>
                                {!isReadOnly && (
                                  <td>
                                    <button
                                      type='button'
                                      data-repeater-delete=''
                                      onClick={() => handleRemoveItem(item?.id)}
                                      className='btn btn-sm btn-bg-light'
                                    >
                                      <KTIcon iconName='trash' className='fs-3 text-danger' />
                                    </button>
                                  </td>
                                )}
                              </tr>
                            )) : <Spinner className='mx-auto' animation='border' color='white' size='sm' />}
                          </tbody>
                          {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export { ProductRelationModalForm }
