import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {DashboardWrapper} from '../modules/apps/dashboard/DashboardWrapper'
import {DashboardForecastWrapper} from '../modules/dashboard-forecast/DashboardForecastWrapper'
import PartnerOrganizationPage from '../modules/partner-organization/PartnerOrganizationPage'
import {RushHourWrapper} from '../modules/report-rush-hour/RushHourWrapper'
import {TrendAnalysisWrapper} from '../modules/report-trend-analysis/TrendAnalysisWrapper'
import {RevenueSharingWrapper} from '../modules/revenue-sharing/RevenueSharingWrapper'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import ProductModiferPage from '@/app/modules/product-modifier/ProductModifierPage'
import SubscriptionPage from '@/app/modules/subscription/SubscriptionPage'
import SubscriptionHistoryPage from '../modules/subscription-history/SubscriptionHistoryPage'
import TopupHistoryPage from '../modules/topup-history/TopupHisotryPage'

const PrivateRoutes = () => {
  const UserManagementPage = lazy(() => import('../modules/user-management/UserManagementPage'))
  const PosPage = lazy(() => import('../modules/pos/PosPage'))
  const OrderPage = lazy(() => import('../modules/order-list/OrderPage'))
  const TransactionHistoryPage = lazy(
    () => import('../modules/transaction-history/TransactionHistoryPage')
  )
  const ClosingShiftHistoryPage = lazy(
    () => import('../modules/closing-shift-history/ClosingShiftHistoryPage')
  )
  const TransactionReportPage = lazy(
    () => import('../modules/report-transaction/TransactionReportPage')
  )
  const BookOrderPage = lazy(() => import('../modules/book-order/BookOrder'))
  const KpiDashboard = lazy(() => import('../../app/modules/kpi-home/KpiDashboard'))
  const KpiEmployeePage = lazy(() => import('../modules/kpi-home/KpiHomePage'))
  const KpiDetailManager = lazy(() => import('../modules/kpi-home/KpiDetailManager'))
  const KpiEmployeeDetail = lazy(() => import('../modules/kpi-home/KpiEmployeeDetail'))
  const ProductPage = lazy(() => import('../modules/product/ProductPage'))
  const ProductCategoryPage = lazy(() => import('../modules/product-category/ProductCategoryPage'))
  const ContractEmployeePage = lazy(
    () => import('../modules/human-resources/contract-employee/ContractEmployeePage')
  )
  const OutletPage = lazy(() => import('../modules/outlet/OutletPage'))
  const PartnershipContractPage = lazy(
    () => import('../modules/partnership-contract/PartnershipContractPage')
  )
  const ProductCollectionPage = lazy(
    () => import('../modules/product-collection/ProductCollectionPage')
  )
  const ProductPricePage = lazy(() => import('../modules/product-price/ProductPricePage'))
  const ProductStockPage = lazy(() => import('../modules/product-stock/ProductStockPage'))
  const ProductStockOpnamePage = lazy(
    () => import('../modules/product-stock-opname/ProductStockOpnamePage')
  )
  const ChartOfAccountPage = lazy(() => import('../modules/chart-of-account/ChartOfAccountPage'))
  const SalesInvoicePage = lazy(() => import('../modules/sales-invoice/SalesInvoicePage'))
  const SalesPaymentChannelPage = lazy(
    () => import('../modules/sales-payment-channel/SalesPaymentChannelPage')
  )
  const PurchaseOrderInvoicePage = lazy(
    () => import('../modules/purchase-order-invoice/PurchaseOrderInvoicePage')
  )
  const ApprovalConfigurationPage = lazy(
    () => import('../modules/approval-configuration/ApprovalConfigurationPage')
  )
  const AssistanceTherapistPage = lazy(
    () => import('../modules/assistance-therapist/AssistanceTherapistPage')
  )
  const AttendanceReportPage = lazy(
    () => import('../modules/human-resources/attendance/AttendanceReportPage')
  )

  const ForcaseTargetSalesPage = lazy(
    () => import('../modules/forecast-target-sales/ForecastTargetSalesPage')
  )

  const CustomerPage = lazy(() => import('../modules/sales-marketing/customer/CustomerPage'))

  const CmsPage = lazy(() => import('../modules/cms/CmsPage'))

  const MappingOutletPicPage = lazy(
    () => import('../modules/mapping-outlet-pic/MappingOutletPicPage')
  )

  const JournalEntryPage = lazy(() => import('../modules/journal-entry/JournalEntryPage'))

  const JournalHistoryPage = lazy(() => import('../modules/journal-history/JournalHistoryPage'))

  const GeneralLedgerPage = lazy(() => import('../modules/general-ledger/GeneralLedgerPage'))

  const ProfitLossPage = lazy(() => import('../modules/profit-loss/ProfitLossPage'))

  const BalanceSheetPage = lazy(() => import('../modules/balance-sheet/BalanceSheetPage'))

  const CashFlowPage = lazy(() => import('../modules/cash-flow/CashFlowPage'))

  const RawMaterialStockPage = lazy(
    () => import('../modules/raw-material-stock/RawMaterialStockPage')
  )

  const RawMaterialStockOpnamePage = lazy(
    () => import('../modules/raw-material-stock-opname/RawMaterialStockOpnamePage')
  )

  const RawMaterialPage = lazy(() => import('../modules/raw-material/RawMaterialPage'))

  const RawMaterialCategoryPage = lazy(
    () => import('../modules/raw-material-category/RawMaterialCategoryPage')
  )

  const RawMaterialPricePage = lazy(
    () => import('../modules/raw-material-price/RawMaterialPricePage')
  )
  const InitialStockRawMaterialPage = lazy(
    () => import('../modules/initial-stock-raw-material/InitialStockRawMaterialPage')
  )
  const StockCardPage = lazy(() => import('../modules/stock-card/StockCardPage'))
  const PurchaseOrderPage = lazy(() => import('../modules/purchase-order/PurchaseOrderPage'))
  const StoreRequestPage = lazy(() => import('../modules/store-request/StoreRequestPage'))
  const StoreRequestApprovalPage = lazy(
    () => import('../modules/store-request-approval/StoreRequestApprovalPage')
  )
  const StoreRequestTransferPage = lazy(
    () => import('../modules/store-request-transfer/StoreRequestTransferPage')
  )
  const StoreRequestReceivePage = lazy(
    () => import('../modules/store-request-receive/StoreRequestReceivePage')
  )
  const PurchaseOrderApprovalPage = lazy(
    () => import('../modules/purchase-order-approval/PurchaseOrderApprovalPage')
  )
  const PurchaseOrderReceivePage = lazy(
    () => import('../modules/purchase-order-receive/PurchaseOrderReceivePage')
  )
  const RawMaterialSupplierPage = lazy(
    () => import('../modules/raw-material-supplier/RawMaterialSupplierPage')
  )
  const SalesByProductPage = lazy(() => import('../modules/sales-by-product/SalesByProductPage'))
  const TransferPage = lazy(() => import('../modules/transfer/TransferPage'))
  const TransferRequestPage = lazy(() => import('../modules/transfer-request/TransferRequestPage'))
  const TransferReceivePage = lazy(() => import('../modules/transfer-receive/TransferReceivePage'))
  const RatingByOrderPage = lazy(() => import('../modules/rating-by-order/RatingByOrderPage'))
  const RatingByTherapistPage = lazy(
    () => import('../modules/rating-by-therapist/RatingByTherapistPage')
  )
  const EmployeePage = lazy(() => import('../modules/human-resources/employee/EmployeePage'))
  const SidebarPage = lazy(() => import('../modules/sidebar/SidebarPage'))

  const MembershipLevelPage = lazy(() => import('../modules/membership-level/MembershipLevelPage'))
  const RedeemPointPage = lazy(() => import('../modules/redeem-point/RedeemPointPage'))
  const VoucherPage = lazy(() => import('../modules/voucher/VoucherPage'))
  const RedeemPointHistoryPage = lazy(
    () => import('../modules/redeem-point-history/RedeemPointHistoryPage')
  )
  const VoucherCategoryPage = lazy(() => import('../modules/voucher-category/VoucherCategoryPage'))
  const ResetPointPage = lazy(() => import('../modules/reset-point/ResetPointPage'))
  const RewardPointPage = lazy(() => import('../modules/reward-point/RewardPointPage'))
  const MembershipTypePage = lazy(() => import('../modules/membership_type/MembershipTypePage'))
  const SalesByOutletPage = lazy(() => import('../modules/sales-by-outlet/SalesByOutletPage'))
  const ProductionRawMaterialInHousePage = lazy(
    () => import('../modules/production-raw-material-in-house/ProductionRawMaterialInHousePage')
  )
  const ModifierBom = lazy(() => import('../modules/bom-modifier/ModifierBomPage'))
  const BmoRawMaterialPage = lazy(() => import('../modules/bmo-raw-material/BmoRawMaterialPage'))
  const BomProduct = lazy(() => import('../modules/bmo-product/BmoProductPage'))
  const ProductionRawMaterialInHouseProcessPage = lazy(
    () =>
      import(
        '../modules/production-raw-material-in-house-process/ProductionRawMaterialInHouseProcessPage'
      )
  )
  const InHouseRawMaterialPage = lazy(
    () => import('../modules/in-house-raw-material/InHouseRawMaterialPage')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='book-order' element={<BookOrderPage />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='report/sales/revenue-sharing' element={<RevenueSharingWrapper />} />
        <Route path='report/sales/sales-forecast' element={<DashboardForecastWrapper />} />
        <Route path='report/sales/rush-hour' element={<RushHourWrapper />} />
        <Route path='report/sales/trend-analysis' element={<TrendAnalysisWrapper />} />
        <Route
          path='report/sales/*'
          element={
            <SuspensedView>
              <SalesPaymentChannelPage />
              <SalesByProductPage />
              <SalesByOutletPage />
            </SuspensedView>
          }
        />
        <Route
          path='report/*'
          element={
            <SuspensedView>
              <RatingByOrderPage />
              <RatingByTherapistPage />
              <RedeemPointHistoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='master-data/*'
          element={
            <SuspensedView>
              <SidebarPage />
            </SuspensedView>
          }
        />
        <Route
          path='master-data/product/*'
          element={
            <SuspensedView>
              <ProductPage />
              <ProductCategoryPage />
              <ProductCollectionPage />
              <ProductPricePage />
              <ProductStockPage />
              <ProductStockOpnamePage />
              <ProductModiferPage />
            </SuspensedView>
          }
        />
        <Route
          path='human-resources/*'
          element={
            <SuspensedView>
              <EmployeePage />
              <ContractEmployeePage />
              <AttendanceReportPage />
            </SuspensedView>
          }
        />
        <Route
          path='master-data/outlet/*'
          element={
            <SuspensedView>
              <OutletPage />
              <PartnershipContractPage />
            </SuspensedView>
          }
        />
        <Route
          path='master-data/raw-material/*'
          element={
            <SuspensedView>
              <RawMaterialPage />
              <RawMaterialCategoryPage />
              <RawMaterialPricePage />
              <InitialStockRawMaterialPage />
            </SuspensedView>
          }
        />
        <Route
          path='raw-material/*'
          element={
            <SuspensedView>
              <RawMaterialStockPage />
              <RawMaterialStockOpnamePage />
              <StoreRequestPage />
              <StoreRequestApprovalPage />
              <StoreRequestTransferPage />
              <StoreRequestReceivePage />
              <PurchaseOrderPage />
              <PurchaseOrderApprovalPage />
              <PurchaseOrderReceivePage />
              <RawMaterialSupplierPage />
              <TransferPage />
              <TransferRequestPage />
              <TransferReceivePage />
              <StockCardPage />
              <ProductionRawMaterialInHousePage />
              <ProductionRawMaterialInHouseProcessPage />
              <BmoRawMaterialPage />
              <InHouseRawMaterialPage />
            </SuspensedView>
          }
        />

        <Route
          path='product/*'
          element={
            <SuspensedView>
              <BomProduct />
              <ModifierBom />
            </SuspensedView>
          }
        />
        {/* <Route
          path='sales-marketing/cms/*'
          element={
            <SuspensedView>
              <CmsPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='finance-of-accounting/*'
          element={
            <SuspensedView>
              <ChartOfAccountPage />
              <JournalEntryPage />
              <JournalHistoryPage />
              <GeneralLedgerPage />
              <ProfitLossPage />
              <BalanceSheetPage />
              <CashFlowPage />
            </SuspensedView>
          }
        />
        <Route
          path='invoice/*'
          element={
            <SuspensedView>
              <PurchaseOrderInvoicePage />
              <SalesInvoicePage />
            </SuspensedView>
          }
        />
        <Route
          path='sales-marketing/*'
          element={
            <SuspensedView>
              <CustomerPage />
              <ForcaseTargetSalesPage />
              <CmsPage />
              <SubscriptionPage />
              <SubscriptionHistoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/store/*'
          element={
            <SuspensedView>
              <PosPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/order/*'
          element={
            <SuspensedView>
              <OrderPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/*'
          element={
            <SuspensedView>
              <TransactionReportPage />
              <TransactionHistoryPage />
              <ClosingShiftHistoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/partner-organization/*'
          element={
            <SuspensedView>
              <PartnerOrganizationPage />
            </SuspensedView>
          }
        />

        {/* Lazy Modules */}
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <ApprovalConfigurationPage />
              <MappingOutletPicPage />
            </SuspensedView>
          }
        />
        <Route
          path='user-management/*'
          element={
            <SuspensedView>
              <MappingOutletPicPage />
              <UserManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='user-management/assistance-therapist/*'
          element={
            <SuspensedView>
              <AssistanceTherapistPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/KPI/*'
          element={
            <SuspensedView>
              <KpiEmployeePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/KPI/therapist'
          element={
            <SuspensedView>
              <KpiEmployeePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/KPI/manager'
          element={
            <SuspensedView>
              <KpiDetailManager />
            </SuspensedView>
          }
        />
        <Route
          path='apps/KPI/manager/detail'
          element={
            <SuspensedView>
              <KpiEmployeeDetail />
            </SuspensedView>
          }
        />
        <Route
          path='crm/*'
          element={
            <SuspensedView>
              <MembershipLevelPage />
              <RedeemPointPage />
              <VoucherPage />
              <VoucherCategoryPage />
              <ResetPointPage />
              <RewardPointPage />
              <MembershipTypePage />
              <TopupHistoryPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
