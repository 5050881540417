import {createContext, FC, useContext, useState} from 'react'
import {WithChildren} from '@/_metronic/helpers'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import { FilterSubscriptionHistory, initialFilter } from './_models'

type SubscriptionHistoryQueryRequestContextProps = {
  state: BaseRequest<FilterSubscriptionHistory>
  updateState: (updates: Partial<BaseRequest<FilterSubscriptionHistory>>) => void
}

const initialQueryRequest = {
  state: initialFilter,
  updateState: () => {},
}

const QueryRequestContext = createContext<SubscriptionHistoryQueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<BaseRequest<FilterSubscriptionHistory>>(initialQueryRequest.state)

  const updateState = (updates: Partial<BaseRequest<FilterSubscriptionHistory>>) => {
    const updatedState = {...state, ...updates} as BaseRequest<FilterSubscriptionHistory>
    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
