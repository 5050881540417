import { KTIcon, isNotEmpty } from '@/_metronic/helpers'
import { DropdownAsyncSelect } from '@/_metronic/partials/content/dropdown-select/DropdownAsyncSelect'
import { DropdownSelect } from '@/_metronic/partials/content/dropdown-select/DropdownSelect'
import { getEmployeeDropdown } from '@/app/core/employee/_requests'
import { getOutletDropdown } from '@/app/core/master-data/outlet/_requests'
import { initialFilter } from '@/app/modules/human-resources/employee/core/_models'
import { initialFilterOutlet } from '@/app/modules/outlet/core/_models'
import { initialFilter as initialFilterProduct } from '@/app/modules/product/core/_models'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useListView } from '../../../core/ListViewProvider'
import { useQueryResponse, useQueryResponseData } from '../../../core/QueryResponseProvider'
import { DropdownSelectAsyncPaginate } from '@/_metronic/partials/content/dropdown-select/DropdownSelectAsyncPaginate'
import { forIn } from 'lodash'
import { Spinner } from 'react-bootstrap'
import { RESP } from '@/_metronic/helpers/network/network-consts'
import { toast } from 'react-toastify'
import { getProducts } from '@/app/modules/product/core/_requests'
import {InputGroup} from '@/_metronic/partials/content/input-group/InputGroup'
import { Toggle } from '@/_metronic/partials/content/toggle/Toggle'
import { useDetailResponse } from '@/app/modules/product-price/core/DetailResponseProvider'
import { useProductPriceResponseData } from '@/app/modules/product-price/core/ProductPriceResponseProvider'
import { useProductPrice } from '@/app/modules/product-price/core/PriceProvider'
import { formatDateString, formatIntl, formatRupiah, removeDots } from '@/app/utils'
import { SubscriptionHistory } from '../../../core/_models'
import { useDetailResponseData } from '@/app/modules/kpi-home/core/DetailResponseProvider'

type Props = {
  isLoading?: boolean
  data?: SubscriptionHistory[]
}

const initialListPrice = {
  product_price_id: '',
  product_variant_sku: '',
  outlet_id: '',
  hpp: 0,
  price: 0,
  discount: 0,
  discount_type: 'decimal',
  minimal_stock: 0,
  sales_type: {
    label: '',
    value: ''
  },
  base_price: 0,
  discount_outlet: 0,
  discount_platform: 0,
  additional_amount: 0,
  sell_price: 0,
  is_active: false,
  action: 'create'
}

const SubscriptionCustomerModalForm: React.FC<Props> = ({data}) => {
  const { id } = useParams()

  return (
    <form className='form d-flex flex-column flex-lg-row'>
      <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
        <div className='card card-flush'>
          <div className='card-body pt-0'>
            <div className='w-100'>
              <table className='w-100'>
                <thead>
                  <th className='px-4 w-60px'>
                    <label className='fw-bold'>No</label>
                  </th>
                  <th className='px-4'>
                    <label className='fw-bold'>Transaction Number</label>
                  </th>
                  <th className='px-4'>
                    <label className='fw-bold'>Name</label>
                  </th>
                  <th className='px-4'>
                    <label className='fw-bold'>Outlet</label>
                  </th>
                  <th className='px-4'>
                    <label className='fw-bold'>Date</label>
                  </th>
                  <th className='px-4'>
                    <label className='fw-bold'>Grand Total</label>
                  </th>
                  <th className='px-4'>
                    <label className='fw-bold'>Payment</label>
                  </th>
                  <th className='px-4'>
                    <label className='fw-bold'>Status</label>
                  </th>
                </thead>
                <tbody>
                {data?.map((form, ix) => {
                  let paymentColor: string
                  switch (form.subscription_transaction_status?.toLowerCase()) {
                    case "awaiting payment":
                      paymentColor = 'bg-light-warning text-warning'
                      break;
                    case "completed": 
                      paymentColor = 'bg-light-success text-success'
                      break;
                    case "cancelled":
                      paymentColor = 'bg-light-danger text-danger'
                      break;
                    default:
                      paymentColor = 'bg-light-secondary text-muted'
                      break;
                  }
                  const statusColor = form.active ? 'bg-light-success text-success' : 'bg-light-secondary text-muted'
                  return(
                    <>
                      <tr
                        key={ix}
                        data-repeater-item=''
                        className='border-bottom'
                      >
                        <td className='p-4'>
                          {ix + 1}
                        </td>
                        <td className='p-4'>
                          {form.subscription_transaction_number}
                        </td>
                        <td className='p-4'>
                          {form.subscription?.subscription_name}
                        </td>
                        <td className='p-4'>
                          {form.outlet?.outlet_name}
                        </td>
                        <td className='p-4'>
                          {formatDateString(form.created_at)}
                        </td>
                        <td className='p-4'>
                          {formatIntl({num: form.grandtotal, fraction: 0, showSign: true})}
                        </td>
                        <td className='p-4'>
                          <label className={`badge ${paymentColor}`}>{form.subscription_transaction_status}</label>
                        </td>
                        <td className='p-4'>
                          <label className={`badge ${statusColor}`}>{form.active ? "Active" : "Not Active"}</label>
                        </td>
                      </tr>
                    </>
                  )})}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export { SubscriptionCustomerModalForm }
