import {KTCard} from '../../../../_metronic/helpers'
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {SubscriptionListTable} from './table/SubscriptionListTable'
import { DetailResponseProvider } from '@/app/modules/product-modifier/core/DetailResponseProvider'
import { ProductRelationModal } from '@/app/modules/product-modifier/product-modifier-list/components/product-relation-modal/ProductRelationModal'
import { SubscriptionListHeader } from '@/app/modules/subscription/subscription-list/components/header/SubscriptionListHeader'

const SubscriptionList = () => {
  const {itemId, setItemId} = useListView()

  return (
    <>
      <KTCard className='shadow-wit-2'>
        <SubscriptionListHeader />
        <SubscriptionListTable />
      </KTCard>
    </>
  )
}

const SubscriptionListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <SubscriptionList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {SubscriptionListWrapper}
