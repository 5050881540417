/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import parse from 'html-react-parser'
import { ProductModifier, ProductModifierDetail } from '@/app/modules/product-modifier/core/_models'
import { formatIntl } from '@/app/utils'

type Props = {
  productDetail: ProductModifierDetail[]
}

const ProductModifierDetailCell: FC<Props> = ({productDetail}) => {
  return (
    <div>
      {
        productDetail.map((item) => (
          <p>{item.name} - {formatIntl({num: item.sell_price, fraction: 0})}</p>
        ))
      }
    </div>
  )
}

export {ProductModifierDetailCell}
