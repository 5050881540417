import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import { Subscription } from '../../subscription/core/_models'

//#region WALLET CUSTOMER

export type WalletCustomer = {
  customer_guid?: string
  customer_name?: string
  customer_phone?: string
  customer_email?: string
  wallet?: WalletCustomerWallet
}

export type WalletCustomerWallet = {
  balance?: number
  total_credit?: number
  total_debit?: number
}

export type FilterWalletCustomer = {
  set_customer_guid?: boolean
  param_customer_guid?: string
  set_customer_name?: boolean
  param_customer_name?: string
}

export type WalletCustomerQueryResponse = BaseResponse<Array<WalletCustomer>>

export const initialWalletCustomer: WalletCustomer = {
  customer_guid: "d9e2db00-9dd4-4ea2-af3f-ee80adab3819",
  customer_name: "Alby A",
  customer_phone: "085757556756",
  customer_email: "albyariaharii@wit.id",
  wallet: {
    balance: 350000,
    total_credit: 350000,
    total_debit: 0
  }
}

export const initialFilterWalletCustomer: BaseRequest<FilterWalletCustomer> = {
  filter: {
    set_customer_guid: false,
    param_customer_guid: '',
    set_customer_name: false,
    param_customer_name: ''
  },
  limit: 10,
  page: 1,
  order: "customer_name",
  sort: "ASC"
}

//#endregion

//#region WALLET HISTORY

export type WalletHistory = {
  guid?: string
  transaction_number?: string
  customer_guid?: string
  customer_name?: string
  total_subtotal?: number
  total_discount?: number
  rounding?: number
  grandtotal?: number
  total_service_fee?: number
  total_amount?: number
  outstanding?: number
  status_transaction?: string
  custom_field?: string
  remarks?: string
  discount?: WalletHistoryDiscount[]
  payment?: WalletHistoryPayment[]
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
}

type WalletHistoryDiscount = {
  guid?: string
  topup_transaction_guid?: string
  discount_guid?: string
  discount_amount?: number
  remarks?: string
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
}

type WalletHistoryPayment = {
  guid?: string
  payment_method_guid?: string
  payment_method_name?: string
  payment_channel_guid?: string
  payment_channel_name?: string
  service_fee?: number
  amount?: number
  remarks?: string
  callback_url?: string
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
}

export type FilterWalletHistory = {
  set_param_guid?: boolean
  param_guid?: string
  set_param_transaction_number?: boolean
  param_transaction_number?: string
  set_param_customer_guid?: boolean
  param_customer_guid?: string
  set_param_status?: boolean
  param_status?: string
  set_param_date?: boolean
  param_from_date?: string
  param_end_date?: string
}

export type WalletHistoryQueryResponse = BaseResponse<Array<WalletHistory>>

export const initialFilterWalletHistory: BaseRequest<FilterWalletHistory> = {
  filter: {
    set_param_guid: false,
    param_guid: '',
    set_param_transaction_number: false,
    param_transaction_number: '',
    set_param_customer_guid: false,
    param_customer_guid: '',
    set_param_status: false,
    param_status: '',
    set_param_date: false,
    param_from_date: '0001-01-01T00:00:00Z',
    param_end_date: '0001-01-01T00:00:00Z'
  },
  limit: 10,
  page: 1,
  order: "created_at",
  sort: "DESC"
}
