import moment from 'moment'
import { FC, ReactNode } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'

type Props = {
  startDate?: string | undefined
  onCallback?: (start: any) => void
  placeholder?: string | undefined
  pickerRef?: React.LegacyRef<DateRangePicker> | undefined
  disabled?: boolean
  readOnly?: boolean
  timePicker?: boolean
  customInput?: ReactNode
  minDate?: any
  maxDate?: any
}

const SingleDatePicker: FC<Props> = ({
  startDate,
  onCallback,
  placeholder,
  pickerRef,
  disabled,
  readOnly,
  timePicker,
  customInput,
  minDate,
  maxDate,
}) => {
  return (
    <DateRangePicker
      ref={pickerRef}
      initialSettings={{
        className: 'bg-primary',
        singleDatePicker: true,
        showDropdowns: true,
        startDate,
        timePicker: timePicker ?? false,
        minDate: minDate,
        maxDate: maxDate,
        disabled: disabled,
      }}
      onCallback={onCallback}
    >
      {customInput ? (
        customInput
      ) : (
        <input
          type='text'
          className='form-control w-100 pe-10'
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
    </DateRangePicker>
  )
}

export { SingleDatePicker }
