/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, lazy} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {App} from '../App'
import {AuthPage, Logout, useAuth} from '../modules/auth'
import ForgotPasswordWrapper from '../modules/auth/forgot-password/ForgotPasswordPage'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import OrderWrapper from '../modules/order/Order'
import RatingWrapper from '../modules/rating/Rating'
import {PrivateRoutes} from './PrivateRoutes'
import OrderSelectOutletWrapper from '../modules/order/OrderSelectOutlet'
import PrivacyPolicyPage from '../modules/privacy-policy/PrivacyPolicyPage'

const MapsView = lazy(() => import('@/app/modules/maps/Maps'))

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser, token} = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='forgot-password/:id' element={<ForgotPasswordWrapper />} />
          <Route path='order/:id' element={<OrderWrapper />} />
          <Route path='order' element={<OrderSelectOutletWrapper />} />
          <Route path='privacy-policy' element={<PrivacyPolicyPage />} />
          <Route path='map-view' element={<MapsView />} />
          <Route path='rating/:id' element={<RatingWrapper />} />

          {currentUser && token ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
