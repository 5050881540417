import {AxiosResponse} from 'axios'
import {axios} from '@/_metronic/helpers/network/Axios'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import { Product } from '@/app/modules/order/core/_models'
import { FilterSubscription, Subscription, SubscriptionQueryResponse } from '@/app/modules/subscription/core/_models'

const CRM_API_URL = '/crm-services'
const SUBSCRIPTION_API_URL = `${CRM_API_URL}/subscription`
const SUBSCRIPTION_LIST_API_URL = `${CRM_API_URL}/subscription/list`
const SUBSCRIPTION_ACTIVE_API_URL = `${SUBSCRIPTION_API_URL}/active`

const getSubscriptions = (body: BaseRequest<FilterSubscription>): Promise<SubscriptionQueryResponse> => {
  return axios
    .post(`${SUBSCRIPTION_LIST_API_URL}`, body)
    .then((d: AxiosResponse<SubscriptionQueryResponse>) => d.data)
}

const getSubscriptionById = (id: string | undefined): Promise<BaseResponse<Subscription>> => {
  return axios
    .get(`${SUBSCRIPTION_API_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<Subscription>>) => d.data)
}

const createSubscription = (body: any): Promise<BaseResponse<any>> => {
  return axios
    .post(SUBSCRIPTION_API_URL, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updateSubscription = (id: string, body: any): Promise<BaseResponse<any>> => {
  return axios
    .put(`${SUBSCRIPTION_API_URL}/${id}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const deleteSubscription = (id: string): Promise<BaseResponse<any>> => {
  return axios
    .delete(`${SUBSCRIPTION_API_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updateSubscriptionStatus = (id: string): Promise<BaseResponse<any>> => {
  return axios
    .put(`${SUBSCRIPTION_ACTIVE_API_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

export {
  getSubscriptions,
  getSubscriptionById,
  createSubscription,
  updateSubscription,
  deleteSubscription,
  updateSubscriptionStatus
}
