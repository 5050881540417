// @ts-nocheck
import {Column} from 'react-table'
import {SubscriptionStatusCell} from './SubscriptionStatusCell'
import { ProductModifier } from '@/app/modules/product-modifier/core/_models'
import { ProductModifierCustomHeader } from '@/app/modules/product-modifier/product-modifier-list/table/columns/ProductModifierCustomHeader'
import { SubscriptionActionsCell } from '@/app/modules/subscription/subscription-list/table/columns/SubscriptionActionsCell'
import { formatIntl } from '@/app/utils'

const productModifiersColumns: ReadonlyArray<Column<ProductModifier>> = [
  {
    Header: (props) => <ProductModifierCustomHeader tableProps={props} title='No' />,
    id: 'no',
    Cell: (props) => {
      return <label>{props.row.index + 1 + props.initialState.pageIndex * 10}</label>
    },
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Outlet' className='min-w-125px' />
    ),
    accessor: 'outlet_name',
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Sell Price' className='min-w-125px' />
    ),
    accessor: 'sell_price',
    Cell: (props) => {
      return formatIntl({num: props.value, fraction: 0, showSign: true}) 
    }
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Expired Days' className='min-w-125px' />
    ),
    accessor: 'expired_days',
    Cell: (props) => {
      return props.value + ' day(s)'
    }
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Active' className='min-w-125px' />
    ),
    id: 'is_active',
    Cell: ({...props}) => <SubscriptionStatusCell subscription={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <SubscriptionActionsCell id={props.data[props.row.index].id} />,
  },
]

export {productModifiersColumns}