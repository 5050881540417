import {BarChart} from '@/_metronic/partials/content/chart/BarChart'
import {useEffect, useState} from 'react'
import {AchievementProgress} from './components/AchievementProgress'
import {useQueryResponseMonthlyData} from './core/QueryResponseMonthlyProvider'
import Maps from '../maps/Maps'
import {FormattedNumber, IntlProvider} from 'react-intl'

const ForecastOverviewMonthly = () => {
  const data = useQueryResponseMonthlyData()
  const [arrayOfSeriesRecentStatisticsSales, setArrayOfSeriesRecentStatisticsSales] = useState<
    number[]
  >([])
  const [arrayOfSeriesRecentStatisticsSalesTarget, setArrayOfSeriesRecentStatisticsSalesTarget] =
    useState<number[]>([])
  const [arrayOfLabelsRecentStatistics, setArrayOfLabelsRecentStatistics] = useState<string[]>([])
  const [arrayOfSeriesRecentStatisticsTreatment, setArrayOfSeriesRecentStatisticsTreatment] =
    useState<number[]>([])
  const [
    arrayOfSeriesRecentStatisticsTreatmentTarget,
    setArrayOfSeriesRecentStatisticsTreatmentTarget,
  ] = useState<number[]>([])

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
  }

  function numberFormatWithCommas(x) {
    return Intl.NumberFormat('in-ID', {
      style: 'currency',
      currency: 'IDR',
      maximumFractionDigits: 2,
    })
      .format(parseFloat(x || '0'))
      .replace('Rp', '')
      .trim()
  }

  useEffect(() => {
    if (data) {
      setArrayOfSeriesRecentStatisticsSales([])
      setArrayOfSeriesRecentStatisticsSalesTarget([])
      setArrayOfLabelsRecentStatistics([])
      setArrayOfSeriesRecentStatisticsTreatment([])
      setArrayOfSeriesRecentStatisticsTreatmentTarget([])
      if (data?.monthly_sales_vs_budget) {
        const arrayOfSeriesSalesString = data?.monthly_sales_vs_budget?.map((obj) =>
          obj.actual_sales ? obj.actual_sales : 0
        )
        setArrayOfSeriesRecentStatisticsSales(arrayOfSeriesSalesString)
        const arrayOfSeriesSalesTargetString = data?.monthly_sales_vs_budget?.map((obj) =>
          obj.sales_target ? obj.sales_target : 0
        )
        setArrayOfSeriesRecentStatisticsSalesTarget(arrayOfSeriesSalesTargetString)
        const arrayOfSeriesTreatmetnString = data?.monthly_sales_vs_budget?.map((obj) =>
          obj.actual_transaction ? obj.actual_transaction : 0
        )
        setArrayOfSeriesRecentStatisticsTreatment(arrayOfSeriesTreatmetnString)
        const arrayOfSeriesTreatmetnTargetString = data?.monthly_sales_vs_budget?.map((obj) =>
          obj.transaction_target ? obj.transaction_target : 0
        )
        setArrayOfSeriesRecentStatisticsTreatmentTarget(arrayOfSeriesTreatmetnTargetString)

        const arrayOfLabelsString = data?.monthly_sales_vs_budget?.map((obj, index) => {
          return obj.month_value ? obj.month_value : ''
        })
        setArrayOfLabelsRecentStatistics(arrayOfLabelsString)
      }
    }
  }, [data])

  return (
    <>
      {/* <div className='mt-8'>
        <h4>Summary Information</h4>
        <div className='d-flex flex-row gap-4 mt-8'>
          <div className='flex-fill'>
            <AverageCard
              className='card-xl mb-xl-8 shadow-wit'
              color='white'
              title='Average Sales'
              subtitle='Sales Reports'
              description='Rp. 145.000.000'
              descriptionColor='info'
            />
          </div>
          <div className='flex-fill'>
            <AverageCard
              className='card-xl mb-xl-8 shadow-wit'
              color='white'
              title='Average Treatments'
              subtitle='Sales Reports'
              description='Rp. 425.000'
              descriptionColor='warning'
            />
          </div>
          <div className='flex-fill'>
            <AverageCard
              className='card-xl mb-xl-8 shadow-wit'
              color='white'
              title='Average Transactions'
              subtitle='Sales Reports'
              description='15'
              descriptionColor='primary'
            />
          </div>
        </div>
      </div> */}

      <Maps />

      <div className='row g-5 g-xl-8 mt-5'>
        <div className='col-xl-6 mb-5'>
          <AchievementProgress
            className='primary shadow-wit'
            chartColor='primary'
            chartHeight='250'
            title='Revenue Achievement'
            subTitle='Compared to your target'
            bar={
              data &&
              data.sales_achievement &&
              data?.sales_achievement?.completion_rate &&
              data?.sales_achievement?.completion_rate?.origin
            }
            actual={
              data && data.sales_achievement && data?.sales_achievement?.actual_sales
                ? 'Rp. ' + numberFormatWithCommas(data?.sales_achievement?.actual_sales)
                : 'Rp. 0'
            }
            target={
              data && data.sales_achievement && data?.sales_achievement?.sales_target
                ? 'Rp. ' + numberFormatWithCommas(data?.sales_achievement?.sales_target)
                : 'Rp. 0'
            }
          />
        </div>

        <div className='col-xl-6'>
          <AchievementProgress
            className='success shadow-wit'
            chartColor='success'
            chartHeight='250'
            title='Products Achievement'
            subTitle='Compared to your target'
            bar={
              data &&
              data.transaction_achievement &&
              data?.transaction_achievement?.completion_rate &&
              data?.transaction_achievement?.completion_rate?.origin
            }
            actual={
              data &&
              data.transaction_achievement &&
              data?.transaction_achievement?.actual_transaction
                ? numberWithCommas(data?.transaction_achievement?.actual_transaction)
                : '0'
            }
            target={
              data &&
              data.transaction_achievement &&
              data?.transaction_achievement?.transaction_target
                ? numberFormatWithCommas(data?.transaction_achievement?.transaction_target)
                : '0'
            }
          />
        </div>
      </div>

      <div className='col-xl-12 mt-10'>
        <BarChart
          className='shadow-wit'
          color='primary'
          title='Actual vs Target (Revenue)'
          subtitle=''
          height='260px'
          series={[
            {
              name: 'Actual',
              data: data?.monthly_sales_vs_budget?.map((obj) =>
                obj.actual_sales ? obj.actual_sales : 0
              ),
            },
            {
              name: 'Target',
              data: data?.monthly_sales_vs_budget?.map((obj) =>
                obj.sales_target ? obj.sales_target : 0
              ),
            },
          ]}
          categories={arrayOfLabelsRecentStatistics}
          total={0}
          isTotal={false}
          data={data?.monthly_sales_vs_budget?.map((x) => ({
            month: `${x.month_value} ${x.year}`,
            actual_sales: x.actual_sales,
            sales_target: x.sales_target,
          }))}
          headers={['Bulan', 'Revenue', 'Revenue Target']}
          formatCurrency={['actual_sales', 'sales_target']}
        />
      </div>

      <div className='col-xl-12 mt-10 mb-3'>
        <BarChart
          className='shadow-wit'
          color='success'
          title='Actual vs Target (Product)'
          subtitle=''
          height='260px'
          series={[
            {
              name: 'Actual',
              data: data?.monthly_sales_vs_budget?.map((obj) =>
                obj.actual_transaction ? obj.actual_transaction : 0
              ),
            },
            {
              name: 'Target',
              data: data?.monthly_sales_vs_budget?.map((obj) =>
                obj.transaction_target ? obj.transaction_target : 0
              ),
            },
          ]}
          categories={arrayOfLabelsRecentStatistics}
          total={0}
          isCurrency={false}
          isTotal={false}
          data={data?.monthly_sales_vs_budget?.map((x) => ({
            month: `${x.month_value} ${x.year}`,
            actual_treatment: x.actual_transaction,
            treatment_target: (
              <IntlProvider locale='id'>
                <FormattedNumber value={x.transaction_target ?? 0} maximumFractionDigits={2} />
              </IntlProvider>
            ),
          }))}
          headers={['Bulan', 'Product', 'Product Target']}
        />
      </div>
    </>
  )
}

export default ForecastOverviewMonthly
