import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'

export type Subscription = {
  id?: string
  outlet_id?: string
  images?: string
  name?: string
  body?: string
  benefit?: string
  price?: number
  discount?: number
  sell_price?: number
  expired_days?: number
  is_include_tax?: boolean
  is_include_service?: boolean
  is_active?: boolean
  details?: SubscriptionDetail[]
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  outlet?: SubscriptionOutlet
}

export type SubscriptionDetail = {
  id?: string
  product_variant_sku?: string
  product_name?: string
  qty?: number,
  detail_product?: SubscriptionDetailProduct
  sell_price?: number
}

type SubscriptionDetailProduct = {
  id?: string,
  name?: string,
  description?: string
  product_images_url_thumbnail?: string,

}

export type FilterSubscription = {
  set_outlet_id?: boolean
  outlet_id?: string
  set_name?: boolean
  name?: string
  set_date?: boolean
  date_from?: string
  date_to?: string
  set_is_active?: boolean
  is_active?: boolean
}

export type SubscriptionOutlet = {
  id?: string
  name?: string
  area?: string
  address?: string
}

export type SubscriptionQueryResponse = BaseResponse<Array<Subscription>>

export const initialSubscription: Subscription = {
  id: "739ad88f-3997-4130-af1b-e54577b402f7",
  outlet_id: "f0d8bb5d-ddd4-453a-896b-42875a634157",
  images: '',
  name: "Two Thrds Subscription Hot Drink",
  price: 800000,
  discount: 50000,
  sell_price: 750000,
  expired_days: 30,
  is_include_tax: true,
  is_include_service: false,
  is_active: true,
  details: [
    {
      id: "e3a8018a-2923-4742-bdad-632b2b990b6c",
      product_variant_sku: "SKU-7",
      product_name: "Hot Black",
      qty: 10
    }
  ],
  created_at: '2024-06-11T07:08:33.746209',
  created_by: "e699a2c3-fb77-4f37-b5b1-ac423e51fd2e",
  updated_at: '2024-06-11T07:20:25.417947',
  updated_by: 'e699a2c3-fb77-4f37-b5b1-ac423e51fd2e'
}

export const initialFilter: BaseRequest<FilterSubscription> = {
  filter: {
    set_outlet_id: false,
    outlet_id: '',
    set_name: false,
    name: '',
    set_date: false,
    date_from: '0001-01-01T00:00:00Z',
    date_to: '0001-01-01T00:00:00Z',
    set_is_active: false,
    is_active: false
  },
  limit: 10,
  page: 1,
  order: "created_at",
  sort: "DESC"
}
