import {FieldArrayRenderProps, useFormikContext} from 'formik'
import {FC} from 'react'
import {RowItem} from './RowItem'

type ValueRange = {
  minimum: number,
  maximum: number
}

type Props = {
  isReadOnly?: boolean
  arrayHelpers: FieldArrayRenderProps
  valueRange: ValueRange
  setIsMaxQtyValid: any
  isMaxQtyValid: any
}
const RowDetails: FC<Props> = ({isReadOnly, arrayHelpers, valueRange, setIsMaxQtyValid, isMaxQtyValid}) => {
  const {values} = useFormikContext<any>()
  return (
    <>
      {values.details && values.details?.length > 0
        ? values.details?.map((item, i) => {
          if (item.action !== "delete") {
            return (
              <RowItem arrayHelpers={arrayHelpers} index={i} isReadOnly={isReadOnly} valueRange={valueRange} setIsMaxQtyValid={setIsMaxQtyValid} isMaxQtyValid={isMaxQtyValid} />
            )
          }
        })
        : null}
    </>
  )
}

export {RowDetails}
