import clsx from 'clsx'
import {ChangeEventHandler, FC, FocusEventHandler} from 'react'

type Props = {
  value?: string | number | readonly string[] | undefined
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  name?: any | undefined
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  checked?: boolean
  defaultChecked?: boolean
  id?: string
  showMargin?: boolean
}

const Toggle: FC<Props> = ({
  checked,
  id,
  onChange,
  name,
  onBlur,
  readOnly,
  disabled,
  defaultChecked,
  showMargin = true,
}) => {
  return (
    <>
      <div className='form-check form-switch form-check-custom form-check-solid'>
        <input
          type='checkbox'
          id={id}
          className={clsx('form-check-input', {
            'me-5': Boolean(name) || showMargin,
            'me-3': !Boolean(name) && !showMargin,
          })}
          defaultChecked={defaultChecked}
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
          disabled={disabled}
        />
        {name === 'Has Access' ? (
          <label className={`form-label mb-0 text-warning text-nowrap`} htmlFor={id}>
            {name}
          </label>
        ) : name === 'Create' ? (
          <label className={`form-label mb-0 text-success text-nowrap`} htmlFor={id}>
            {name}
          </label>
        ) : name === 'Update' ? (
          <label className={`form-label mb-0 text-primary text-nowrap`} htmlFor={id}>
            {name}
          </label>
        ) : name === 'Delete' ? (
          <label className={`form-label mb-0 text-danger text-nowrap`} htmlFor={id}>
            {name}
          </label>
        ) : (
          <label className={`form-label mb-0`} htmlFor={id}>
            {name}
          </label>
        )}
      </div>
    </>
  )
}

export {Toggle}
