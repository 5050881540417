import {useAuth} from '@/app/modules/auth'
import {FilterOutlet} from '@/app/modules/outlet/core/_models'
import {getOutlets} from '@/app/modules/outlet/core/_requests'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {
  DropdownSelectAsyncPaginate,
  OptionType,
} from '@/_metronic/partials/content/dropdown-select/DropdownSelectAsyncPaginate'
import {FC, useEffect, useState} from 'react'
import {MultiValue} from 'react-select'
import { getCustomers } from '@/app/modules/sales-marketing/customer/core/_requests'
import { initialFilter } from '@/app/modules/sales-marketing/customer/core/_models'

type Props = {
  currentValue?: OptionType | MultiValue<OptionType> | null
  onValueChange?: any
  className?: string
  isDisabled?: boolean
}

const FilterDropdownCustomer: FC<Props> = ({currentValue, onValueChange, isDisabled}) => {
  const [_, onChange] = useState<OptionType | MultiValue<OptionType> | null>(currentValue || null)

  const {currentUser} = useAuth()

  const loadOptionsCustomer = async (search: string, page: number) => {
    try {
      const body = {
        filter: {
          ...initialFilter.filter,
          set_full_name: Boolean(search),
          full_name: search,
        },
        limit: 10,
        page,
        order: 'created_at',
        sort: 'ASC',
      } as BaseRequest<FilterOutlet>

      const resp = await getCustomers(body)
      if (resp?.response?.data) {
        const {data} = resp.response
        return {
          options: data?.map((x) => ({label: x.full_name, value: x.guid})),
          hasMore: page < (resp?.response?.total_page || 0),
        }
      }

      return {options: [], hasMore: false, additional: page}
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      }
    }
  }

  const loadOptions: any = async (q, _, additional) => {
    const {page} = additional!
    const {options: responseOptions, hasMore} = await loadOptionsCustomer(q, page)

    return {
      options: responseOptions,
      hasMore,
      additional: {
        page: page + 1,
      },
    }
  }

  useEffect(() => {
    onValueChange(currentValue)
  }, [currentValue])

  return (
    <DropdownSelectAsyncPaginate
      key={'customer'}
      placeholder='Select Customer'
      loadOptions={loadOptions}
      onChange={(newValues) => onValueChange(newValues)}
      value={currentValue}
      isDisabled={isDisabled}
      // className='min-w-300px'
      isClearable
      type='filled'
    />
  )
}

export {FilterDropdownCustomer}
