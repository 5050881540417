import { MenuComponent } from '@/_metronic/assets/ts/components'
import { KTIcon } from '@/_metronic/helpers'
import PropagateWrapper from '@/_metronic/partials/content/propagate/PropagateWrapper'
import { FilterDropdownCategory } from '@/app/modules/product-stock/product-stock-list/components/header/FilterDropdownCategory'
import { useEffect, useMemo, useState } from 'react'
import { useQueryRequest } from '../../../core/QueryRequestProvider'
import { useQueryResponse } from '../../../core/QueryResponseProvider'
import { initialFilter, initialFilterSubscriptionCustomer } from '../../../core/_models'
import { DropdownSelect } from '@/_metronic/partials/content/dropdown-select/DropdownSelect'
import { useAuth } from '@/app/modules/auth'
import { FilterDropdownCustomer } from './FilterDropdownCustomer'
import { useCustomerRequest } from '../../../core/CustomerRequestProvider'
import { useCustomerResponse } from '../../../core/CustomerResponseProvider'

const optionStatus = [
  {
    label: "Awaiting Payment",
    value: "Awaiting Payment"
  },
  {
    label: "Completed",
    value: "Completed"
  },
  {
    label: "Canceled",
    value: "Canceled"
  }
]

const SubscriptionCustomerListFilter = () => {
  const {updateState} = useCustomerRequest()
  const [filterValue, setFilterValue] = useState(initialFilterSubscriptionCustomer.filter)
  const [category, setCategory] = useState<any>(null)
  const [customer, setCustomer] = useState<any>(undefined)
  const {isLoading} = useCustomerResponse()
  
  const {currentUser} = useAuth()
  const userOutlet = currentUser?.detail_data?.outlet
  const hasOutlet = useMemo(() => {
    return Boolean(userOutlet?.outlet_id && userOutlet?.outlet_name)
  }, [currentUser, userOutlet])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setFilterValue(initialFilter.filter)
    setCategory(null)
    setCustomer(null)
    updateState({filter: initialFilter.filter, page: 1})
  }

  const filterData = () => {
    updateState({
      filter: filterValue,
      page: 1,
      ...updateState,
    })
  }

  return (
    <>
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <PropagateWrapper>
            {/* begin::Input group */}
            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold'>Customer:</label>
              <FilterDropdownCustomer
                onValueChange={(newValue) => {
                  setCustomer(newValue)
                  setFilterValue({
                    ...filterValue,
                    param_customer_guid: newValue?.value,
                    set_customer_guid: Boolean(newValue?.value),
                  })
                }}
                currentValue={customer}
                isDisabled={hasOutlet}
              />
            </div>
            {/* end::Input group */}

            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold'>Status:</label>
              <DropdownSelect
                options={optionStatus}
                value={filterValue?.subscription_transaction_status && filterValue?.subscription_transaction_status?.length > 0 ? optionStatus.find((item) => item.value === filterValue?.subscription_transaction_status?.[0]) : null}
                onChange={(e) => setFilterValue({
                  ...filterValue,
                  set_subscription_transaction_status: Boolean(e.value !== undefined),
                  subscription_transaction_status: [e.value]
                })}
                placeholder="Select Status"
              />
            </div>
          </PropagateWrapper>

          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export { SubscriptionCustomerListFilter }

