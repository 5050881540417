/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'
import {MenuComponent} from '@/_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '@/_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'
import {useQueryResponse} from '../../../core/QueryResponseProvider'
import {useSwal} from '@/app/core/SwalProvider'
import {toast} from 'react-toastify'
import { deleteSubscription } from '@/app/modules/subscription/core/_requests'
import { useCustomerListView } from '../../../core/CustomerListViewProvider'
// import {deleteUser} from '../../core/_requests'

type Props = {
  id: string
}

const SubscriptionCustomerActionsCell: FC<Props> = ({id}) => {
  // const {setItemIdForUpdate} = useListView()
  const { setItemId, setItemIdForUpdate } = useCustomerListView()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <button
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-5'
          onClick={() => {
            setItemId!(id)
            setItemIdForUpdate(1)
          }}
        >
          <KTIcon iconName='eye' className='fs-3 text-primary' />
        </button>
      </div>
      {/* end::Menu */}
    </>
  )
}

export {SubscriptionCustomerActionsCell}
