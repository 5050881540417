import { useQuery } from 'react-query'
import { useListView } from '../../../core/ListViewProvider'
import { isNotEmpty, QUERIES } from '@/_metronic/helpers'
import { getProductModifierById } from '../../../core/_requests'
import { Spinner } from 'react-bootstrap'
import { ProductRelationModalForm } from '@/app/modules/product-modifier/product-modifier-list/components/product-relation-modal/ProductRelationModalForm'
// import {getUserById} from '../core/_requests'

const ProductRelationModalFormWrapper = () => {
  const { itemId, setItemId } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemId)
  const {
    isLoading,
    data: response,
    error,
  } = useQuery(
    `${QUERIES.MAPPING_OUTLET_PIC_LIST}-mapping-outlet-list-${itemId}`,
    () => {
      return getProductModifierById(itemId)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemId!(undefined!)
        console.error(err)
      },
    }
  )

  if (!itemId) {
    return <ProductRelationModalForm data={undefined!} />
  }

  if (isLoading) {
    return <div className="d-flex justify-content-center align-items-center">
      <Spinner animation="border" size="sm" style={{ width: "50px", height: "50px" }}/> {/* Adjust size here */}
    </div>
  }

  if (!isLoading && !error && response) {
    return <ProductRelationModalForm isLoading={isLoading} data={response?.response?.data!} />
  }

  return null
}

export { ProductRelationModalFormWrapper }
