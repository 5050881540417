import Axios, {AxiosRequestHeaders} from 'axios'
import {TOKEN} from '../../../app/modules/auth'
import {API_MESSAGE} from './network-consts'
import * as auth from '@/app/modules/auth/core/AuthHelpers'

interface AlayaHeaderProps extends AxiosRequestHeaders {
  token: string
}

const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
})

axios.defaults.headers['Content-Type'] = 'application/json'

axios.interceptors.request.use(async (request) => {
  const token = localStorage.getItem(TOKEN)

  const headers = {
    ...request.headers,
    token,
  }

  if (!request.headers['Content-Type']) {
    headers['Content-Type'] = 'application/json'
  }

  request.headers = headers as AlayaHeaderProps

  return request
})

axios.interceptors.response.use(undefined, async (error) => {
  const {response, config} = error
  if (!config) {
    return Promise.reject(error)
  }

  if (response) {
    const message = response?.data?.response?.message_en

    // token not provided
    if (message === API_MESSAGE.TOKEN_NOT_FOUND) {
      const token = await auth.fetchToken()

      if (token) {
        config._retry = true
        config.headers = {
          ...config.headers,
          token,
          'Content-Type': 'application/json',
        }
        return axios.request(config)
      }
    }

    // token expired
    if (message === API_MESSAGE.UNAUTHORIZED) {
      auth.removeToken()
    }

    // not logged in
    // if (message === API_MESSAGE.USER_NOT_LOGGED_IN) {
    //   if (window.location.pathname !== '/auth') {
    //     window.location.href = '/auth'
    //   }
    // }
  }
  return Promise.reject(error)
})

export {axios}
