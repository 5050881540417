import { useQuery } from 'react-query'
import { useListView } from '../../../core/ListViewProvider'
import { isNotEmpty, QUERIES } from '@/_metronic/helpers'
import { Spinner } from 'react-bootstrap'
import { SubscriptionCustomerModalForm } from './SubscriptionCustomerModalForm'
import { getSubscriptionHistories, getSubscriptionHistoryById } from '../../../core/_requests'
import { FilterSubscriptionHistory, initialFilter } from '../../../core/_models'
import { BaseRequest } from '@/_metronic/helpers/crud-helper/base-request'
import { useCustomerListView } from '../../../core/CustomerListViewProvider'
// import {getUserById} from '../core/_requests'

const SubscriptionCustomerModalFormWrapper = () => {
  const { itemId, setItemId } = useCustomerListView()
  const enabledQuery: boolean = isNotEmpty(itemId)
  const {
    isLoading,
    data: response,
    error,
  } = useQuery(
    `${QUERIES.MAPPING_OUTLET_PIC_LIST}-mapping-outlet-list-${itemId}`,
    () => {
      const body: BaseRequest<FilterSubscriptionHistory> = {
        ...initialFilter,
        filter: {
          ...initialFilter.filter,
          set_customer_guid: true,
          customer_guid: itemId
        },
        limit: 10000
      }
      return getSubscriptionHistories(body)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemId!(undefined!)
        console.error(err)
      },
    }
  )

  if (!itemId) {
    return <SubscriptionCustomerModalForm data={undefined!} />
  }

  if (isLoading) {
    return <div className="d-flex justify-content-center align-items-center">
      <Spinner animation="border" size="sm" style={{ width: "50px", height: "50px" }}/> {/* Adjust size here */}
    </div>
  }

  if (!isLoading && !error && response) {
    return <SubscriptionCustomerModalForm isLoading={isLoading} data={response.response?.data!} />
  }

  return null
}

export { SubscriptionCustomerModalFormWrapper }
