import {createContext, FC, useContext, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {
  initialQueryState,
  PaginationState,
  QUERIES,
  WithChildren,
  WITPaginationState,
} from '@/_metronic/helpers'
import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {useQueryRequest} from './QueryRequestProvider'
import { FilterWalletHistory, initialFilterWalletHistory, WalletHistory } from './_models'
import { getWalletHistories } from './_requests'

/**
 * Begin: Initial Values
 */
export const initialQueryResponse = {refetch: () => {}, isLoading: false, request: initialFilterWalletHistory}

/**
 * Begin: Response Context
 *
 * It used for context response wrapper, so the response can
 * called everywhere, as long as it wrapped inside the context
 */
type WalletHistoryQueryResponseContextProps = {
  response?: BaseResponse<Array<WalletHistory>> | undefined
  refetch: () => void
  isLoading: boolean
  request: BaseRequest<FilterWalletHistory>
}

function createResponseContext<T>(initialState: WalletHistoryQueryResponseContextProps) {
  return createContext(initialState)
}

const QueryResponseContext = createResponseContext<WalletHistory>(initialQueryResponse)

/**
 * Begin: The Provider
 */
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [request, setRequest] = useState<BaseRequest<FilterWalletHistory>>(state)
  const updatedQuery = useMemo(() => state, [state])

  useEffect(() => {
    if (request !== updatedQuery) {
      setRequest(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.WALLET_HISTORY}-list-${JSON.stringify(request)}`,
    () => {
      return getWalletHistories(request)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, request}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: WITPaginationState = {
    page: 1,
    pageSize: 10,
    totalCount: 1,
  }

  const {response} = useQueryResponse()
  if (!response || !response.response) {
    return defaultPaginationState
  }
  const res = response.response
  return {
    page: res.current_page,
    pageSize: res.limit,
    totalCount: res.total_data,
  } as WITPaginationState
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
