/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'
import {MenuComponent} from '@/_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '@/_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'
import {useQueryResponse} from '../../../core/QueryResponseProvider'
import {useSwal} from '@/app/core/SwalProvider'
import {toast} from 'react-toastify'
import { deleteProductModifier } from '@/app/modules/product-modifier/core/_requests'
// import {deleteUser} from '../../core/_requests'

type Props = {
  id: string
}

const ProductModifierActionsCell: FC<Props> = ({id}) => {
  // const {setItemIdForUpdate} = useListView()
  const {request} = useQueryResponse()
  const queryClient = useQueryClient()
  const { setItemId, setItemIdForUpdate } = useListView()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const {swal} = useSwal()

  const deleteItem = useMutation(() => deleteProductModifier(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.PRODUCT_LIST}-${JSON.stringify(request)}`])
      toast.success('Product modifier berhasil dihapus')
    },
  })

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <div onClick={() => {
          setItemId!(id)
          setItemIdForUpdate(0)
        }}>
          <a href='#' className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-5'>
            <KTIcon iconName='menu' className='fs-3 text-warning' />
          </a>
        </div>
        <div onClick={() => {
          setItemId!(id)
          setItemIdForUpdate(1)
        }}>
          <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-5'>
            <KTIcon iconName='eye' className='fs-3 text-info' />
          </a>
        </div>
        <Link to={`/master-data/product/modifier/edit/${id}`} className='link-primary'>
          <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-5'>
            <KTIcon iconName='pencil' className='fs-3 text-primary' />
          </a>
        </Link>
        <button
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          type='button'
          onClick={() => {
            swal
              ?.fire({
                html: 'Apakah anda yakin ingin menghapus data ini?',
                icon: 'warning',
                buttonsStyling: false,
                confirmButtonText: 'Ya, hapus!',
                cancelButtonText: 'Tidak, batal',
                showCancelButton: true,
                customClass: {
                  confirmButton: 'btn fw-bold btn-danger',
                  cancelButton: 'btn fw-bold btn-active-light-primary',
                },
              })
              .then(async (value) => {
                if (value.isConfirmed) {
                  await deleteItem.mutateAsync()
                  // swal.fire({
                  //   title: 'Data berhasil dihapus',
                  //   icon: 'success',
                  //   buttonsStyling: false,
                  //   confirmButtonText: 'Ok, mengerti!',
                  //   customClass: {
                  //     confirmButton: 'btn fw-bold btn-primary',
                  //   },
                  // })
                }
              })
          }}
        >
          <KTIcon iconName='trash' className='fs-3 text-danger' />
        </button>
      </div>
      {/* end::Menu */}
    </>
  )
}

export {ProductModifierActionsCell}
