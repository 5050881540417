import {WithChildren} from '@/_metronic/helpers'
import {createContext, FC, useContext, useState} from 'react'

type ProductModifierFormContextProps = {
  toDelete: any[]
  toCreate: any[]
  setToDelete: (items: any[]) => void
  setToCreate: (items: any[]) => void
}

const ProductModifierFormContext = createContext<ProductModifierFormContextProps>({
  toDelete: [],
  toCreate: [],
  setToDelete: () => {},
  setToCreate: () => {},
})

const ProductModifierFormProvider: FC<WithChildren> = ({children}) => {
  const [toDelete, setToDelete] = useState<any[]>([])
  const [toCreate, setToCreate] = useState<any[]>([])

  return (
    <ProductModifierFormContext.Provider value={{toDelete, toCreate, setToDelete, setToCreate}}>
      {children}
    </ProductModifierFormContext.Provider>
  )
}

const useProductProviderForm = () => useContext(ProductModifierFormContext)

export {ProductModifierFormProvider, useProductProviderForm}
