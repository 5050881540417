// @ts-nocheck
import {Column} from 'react-table'
import {ProductModifierStatusCell} from './ProductModifierStatusCell'
import {ProductModifierDetailCell} from './ProductModifierDetailCell'
import { ProductModifier } from '@/app/modules/product-modifier/core/_models'
import { ProductModifierCustomHeader } from '@/app/modules/product-modifier/product-modifier-list/table/columns/ProductModifierCustomHeader'
import { ProductModifierActionsCell } from '@/app/modules/product-modifier/product-modifier-list/table/columns/ProductModifierActionsCell'

const productModifiersColumns: ReadonlyArray<Column<ProductModifier>> = [
  {
    Header: (props) => <ProductModifierCustomHeader tableProps={props} title='No' />,
    id: 'no',
    Cell: (props) => {
      return <label>{props.row.index + 1 + props.initialState.pageIndex * 10}</label>
    },
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Modifier' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Detail' className='min-w-125px' />
    ),
    id: 'detail',
    Cell: ({...props}) => <ProductModifierDetailCell productDetail={props.data[props.row.index].modifier_detail} />,
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Product' className='min-w-125px' />
    ),
    accessor: 'count_product_applied_modifier',
    Cell: (props) => {
      return props.value + ' product(s)'
    }
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Active' className='min-w-125px' />
    ),
    id: 'is_active',
    Cell: ({...props}) => <ProductModifierStatusCell product={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ProductModifierActionsCell id={props.data[props.row.index].id} />,
  },
]

export {productModifiersColumns}
