import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { ProductModifierListWrapper } from '@/app/modules/product-modifier/product-modifier-list/ProductModifierList'
import { ProductModifierEditWrapper } from '@/app/modules/product-modifier/product-modifier-edit/ProductModifierEdit'

const productsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Product Modifier',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const addProductsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Product Modifier',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Product Modifier List',
    path: '/master-data/product/modifier/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProductModiferPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/modifier/list'
          element={
            <>
              <PageTitle breadcrumbs={productsBreadcrumbs}>Product Modifier List</PageTitle>
              <ProductModifierListWrapper />
            </>
          }
        />
        <Route
          path='/modifier/add'
          element={
            <>
              <PageTitle breadcrumbs={addProductsBreadcrumbs}>Add Modifier</PageTitle>
              <ProductModifierEditWrapper />
            </>
          }
        />
        <Route
          path='/modifier/edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={addProductsBreadcrumbs}>Edit Modifier</PageTitle>
              <ProductModifierEditWrapper />
            </>
          }
        />
        <Route
          path='/modifier/detail/:id'
          element={
            <>
              <PageTitle breadcrumbs={addProductsBreadcrumbs}>Detail Modifier</PageTitle>
              <ProductModifierEditWrapper />
            </>
          }
        />
        <Route index element={<Navigate to='/master-data/product/modifier/list' />} />
      </Route>
    </Routes>
  )
}

export default ProductModiferPage
