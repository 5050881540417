// @ts-nocheck
import {Column} from 'react-table'
import { ProductModifierCustomHeader } from '@/app/modules/product-modifier/product-modifier-list/table/columns/ProductModifierCustomHeader'
import { formatIntl } from '@/app/utils'
import { SubscriptionCustomer } from '../../../core/_models'

const productModifiersColumns: ReadonlyArray<Column<SubscriptionCustomer>> = [
  {
    Header: (props) => <ProductModifierCustomHeader tableProps={props} title='No' />,
    id: 'no',
    Cell: (props) => {
      return <label>{props.row.index + 1 + props.initialState.pageIndex * 10}</label>
    },
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'customer_name',
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Phone' className='min-w-125px' />
    ),
    accessor: 'customer_phone',
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Email' className='min-w-125px' />
    ),
    accessor: 'customer_email',
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Debit' className='min-w-125px' />
    ),
    accessor: 'total_debit',
    Cell: ({...props}) => (
      <label className='text-danger fw-bold'>{props.data[props.row.index].wallet ? formatIntl({num: props.data[props.row.index].wallet?.total_debit, fraction: 0, showSign: true}) : "-"}</label>
    )
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Credit' className='min-w-125px' />
    ),
    accessor: 'total_credit',
    Cell: ({...props}) => (
      <label className='text-success fw-bold'>{props.data[props.row.index].wallet ? formatIntl({num: props.data[props.row.index].wallet?.total_credit, fraction: 0, showSign: true}) : "-"}</label>
    )
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Balance' className='min-w-125px' />
    ),
    accessor: 'balance',
    Cell: ({...props}) => (
      <label className='text-info fw-bold'>{props.data[props.row.index].wallet ? formatIntl({num: props.data[props.row.index].wallet?.balance, fraction: 0, showSign: true}) : "-"}</label>
    )
  },
]

export {productModifiersColumns}