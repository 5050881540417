import {AxiosResponse} from 'axios'
import {axios} from '@/_metronic/helpers/network/Axios'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import { FilterSubscriptionCustomer, FilterSubscriptionHistory, SubscriptionCustomerQueryResponse, SubscriptionHistory, SubscriptionHistoryQueryResponse } from './_models'

const CRM_API_URL = '/crm-services'
const SUBSCRIPTION_TRANSACTION_API_URL = `${CRM_API_URL}/subscription/transaction`
const SUBSCRIPTION_TRANSACTION_HISTORY_API_URL = `${SUBSCRIPTION_TRANSACTION_API_URL}/history`
const SUBSCRIPTION_TRANSACTION_CUSTOMER_API_URL = `${SUBSCRIPTION_TRANSACTION_API_URL}/customer`

//#region SUBSCRIPTION HISTORY

const getSubscriptionHistories = (body: BaseRequest<FilterSubscriptionHistory>): Promise<SubscriptionHistoryQueryResponse> => {
  return axios
    .post(`${SUBSCRIPTION_TRANSACTION_HISTORY_API_URL}`, body)
    .then((d: AxiosResponse<SubscriptionHistoryQueryResponse>) => d.data)
}

const getSubscriptionHistoryById = (id: string | undefined): Promise<BaseResponse<SubscriptionHistory>> => {
  return axios
    .get(`${SUBSCRIPTION_TRANSACTION_API_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<SubscriptionHistory>>) => d.data)
}

//#endregion

//#region SUBSCRIPTION CUSTOMER

const getSubscriptionCustomers = (body: BaseRequest<FilterSubscriptionCustomer>): Promise<SubscriptionCustomerQueryResponse> => {
  return axios
    .post(`${SUBSCRIPTION_TRANSACTION_CUSTOMER_API_URL}`, body)
    .then((d: AxiosResponse<SubscriptionCustomerQueryResponse>) => d.data)
}

//#endregion

export {
  getSubscriptionHistories,
  getSubscriptionHistoryById,
  getSubscriptionCustomers
}
