import { KTIcon, isNotEmpty } from '@/_metronic/helpers'
import { DropdownAsyncSelect } from '@/_metronic/partials/content/dropdown-select/DropdownAsyncSelect'
import { DropdownSelect } from '@/_metronic/partials/content/dropdown-select/DropdownSelect'
import { getEmployeeDropdown } from '@/app/core/employee/_requests'
import { getOutletDropdown } from '@/app/core/master-data/outlet/_requests'
import { initialFilter } from '@/app/modules/human-resources/employee/core/_models'
import { initialFilterOutlet } from '@/app/modules/outlet/core/_models'
import { initialFilter as initialFilterProduct } from '@/app/modules/product/core/_models'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useListView } from '../../../core/ListViewProvider'
import { useQueryResponse, useQueryResponseData } from '../../../core/QueryResponseProvider'
import { DropdownSelectAsyncPaginate } from '@/_metronic/partials/content/dropdown-select/DropdownSelectAsyncPaginate'
import { forIn } from 'lodash'
import { Spinner } from 'react-bootstrap'
import { RESP } from '@/_metronic/helpers/network/network-consts'
import { toast } from 'react-toastify'
import { getProducts } from '@/app/modules/product/core/_requests'
import {InputGroup} from '@/_metronic/partials/content/input-group/InputGroup'
import { Toggle } from '@/_metronic/partials/content/toggle/Toggle'
import { useDetailResponse } from '@/app/modules/product-price/core/DetailResponseProvider'
import { useProductPriceResponseData } from '@/app/modules/product-price/core/ProductPriceResponseProvider'
import { useProductPrice } from '@/app/modules/product-price/core/PriceProvider'
import { formatIntl, formatRupiah, removeDots } from '@/app/utils'
import { useDetailResponseData } from '@/app/modules/kpi-home/core/DetailResponseProvider'
import { WalletHistory } from '../../../core/_models'

type Props = {
  isLoading?: boolean
  data?: WalletHistory
}

const initialListPrice = {
  product_price_id: '',
  product_variant_sku: '',
  outlet_id: '',
  hpp: 0,
  price: 0,
  discount: 0,
  discount_type: 'decimal',
  minimal_stock: 0,
  sales_type: {
    label: '',
    value: ''
  },
  base_price: 0,
  discount_outlet: 0,
  discount_platform: 0,
  additional_amount: 0,
  sell_price: 0,
  is_active: false,
  action: 'create'
}

const TopupHistoryModalForm: React.FC<Props> = ({data}) => {
  const { id } = useParams()

  return (
    <form className='form d-flex flex-column flex-lg-row'>
      <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
        <div className='card card-flush'>
          <div className='card-body pt-0'>
            <div className='w-100'>
              <div className='row mt-1 justify-content-between'>
                <div className='col-6'>
                  <div className='col-12'>
                    <InputGroup
                      label='Transaction Number'
                      value={data?.transaction_number}
                      disabled={true}
                      required={false}
                    />
                  </div>
                  <div className='col-12'>
                    <InputGroup
                      label='Customer'
                      value={data?.customer_name}
                      disabled={true}
                      required={false}
                    />
                  </div>
                  <div className='col-12'>
                    <InputGroup
                      label='Status'
                      value={data?.status_transaction}
                      disabled={true}
                      required={false}
                    />
                  </div>
                  <div className='w-100 row mt-10 mb-10'>
                    <table className='w-100 mt-5'>
                      <thead>
                        <th className='px-4 w-60px'>
                          <label className='fw-bold'>No</label>
                        </th>
                        <th className='px-4'>
                          <label className='fw-bold'>Payment</label>
                        </th>
                        <th className='px-4'>
                          <label className='fw-bold'>Channel</label>
                        </th>
                        <th className='px-4'>
                          <label className='fw-bold'>Service Fee</label>
                        </th>
                        <th className='px-4'>
                          <label className='fw-bold'>Amount</label>
                        </th>
                      </thead>
                      <tbody>
                      {data?.payment?.map((form, ix) => (
                        <tr
                          key={ix}
                          data-repeater-item=''
                          className='border-bottom'
                        >
                          <td className='p-4'>
                            {ix + 1}
                          </td>
                          <td className='p-4'>
                            {form.payment_method_name}
                          </td>
                          <td className='p-4'>
                            {form.payment_channel_name}
                          </td>
                          <td className='p-4'>
                            {formatIntl({num: form.service_fee, fraction: 0, showSign: true})}
                          </td>
                          <td className='p-4'>
                            {formatIntl({num: form.amount, fraction: 0, showSign: true})}
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='col-6'>
                  <h3 className='mb-4'>Summary</h3>
                  <div className='border p-5'>
                    <div className='col-12 mb-4'>
                      <div className='d-flex justify-content-between'>
                        <label className='fs-5'>Subtotal</label>
                        <label className='fs-4 fw-bold'>{formatIntl({num: data?.total_subtotal, fraction: 0, showSign: true})}</label>
                      </div>
                    </div>
                    <div className='col-12 mb-4'>
                      <div className='d-flex justify-content-between'>
                        <label className='fs-5'>Discount</label>
                        <label className='fs-4 fw-bold'>{formatIntl({num: data?.total_discount, fraction: 0, showSign: true})}</label>
                      </div>
                    </div>
                    <div className='col-12 mb-4'>
                      <div className='d-flex justify-content-between'>
                        <label className='fs-5'>Rounding</label>
                        <label className='fs-4 fw-bold'>{formatIntl({num: data?.rounding, fraction: 0, showSign: true})}</label>
                      </div>
                    </div>
                    <div className='col-12 mb-4'>
                      <div className='d-flex justify-content-between'>
                        <label className='fs-5'>Grandtotal</label>
                        <label className='fs-4 fw-bold'>{formatIntl({num: data?.grandtotal, fraction: 0, showSign: true})}</label>
                      </div>
                    </div>
                    <div className='col-12 mb-4'>
                      <div className='d-flex justify-content-between'>
                        <label className='fs-5'>Service Fee</label>
                        <label className='fs-4 fw-bold'>{formatIntl({num: data?.total_service_fee, fraction: 0, showSign: true})}</label>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='d-flex justify-content-between'>
                        <label className='fs-5'>Amount</label>
                        <label className='fs-4 fw-bold'>{formatIntl({num: data?.total_amount, fraction: 0, showSign: true})}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export { TopupHistoryModalForm }
