import { SubscriptionListSearchComponent } from "./SubscriptionListSearchComponent"
import { SubscriptionListToolbar } from "./SubscriptionListToolbar"

const SubscriptionListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <SubscriptionListSearchComponent />
      <div className='card-toolbar'>
        <SubscriptionListToolbar />
      </div>
    </div>
  )
}

export {SubscriptionListHeader}
