import {useAuth} from '@/app/modules/auth'
import {FilterOutlet} from '@/app/modules/outlet/core/_models'
import {getOutlets} from '@/app/modules/outlet/core/_requests'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {
  DropdownSelectAsyncPaginate,
  OptionType,
} from '@/_metronic/partials/content/dropdown-select/DropdownSelectAsyncPaginate'
import {FC, useEffect, useState} from 'react'
import {MultiValue} from 'react-select'

type Props = {
  currentValue?: OptionType | MultiValue<OptionType> | null
  onValueChange?: any
  className?: string
  isDisabled?: boolean
}

const FilterDropdownOutlet: FC<Props> = ({currentValue, onValueChange, isDisabled}) => {
  const [_, onChange] = useState<OptionType | MultiValue<OptionType> | null>(currentValue || null)

  const {currentUser} = useAuth()

  const loadOptionsOutlet = async (search: string, page: number) => {
    try {
      const body = {
        filter: {
          set_name: Boolean(search),
          name: search,
          set_phone_number: false,
          phone_number: '',
          set_email: false,
          email: '',
          set_brand_id: false,
          brand_id: '',
          set_group_id: false,
          group_id: '',
          set_region_id: false,
          region_id: '',
          set_city_id: false,
          city_id: '',
          set_outlet_code: false,
          outlet_code: '',
        },
        limit: 10,
        page,
        order: 'created_at',
        sort: 'ASC',
      } as BaseRequest<FilterOutlet>

      const resp = await getOutlets(body)
      if (resp?.response?.data) {
        const {data} = resp.response
        return {
          options: data?.map((x) => ({label: x.name, value: x.guid})),
          hasMore: page < (resp?.response?.total_page || 0),
        }
      }

      return {options: [], hasMore: false, additional: page}
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      }
    }
  }

  const loadOptions: any = async (q, _, additional) => {
    const {page} = additional!
    const {options: responseOptions, hasMore} = await loadOptionsOutlet(q, page)

    return {
      options: responseOptions,
      hasMore,
      additional: {
        page: page + 1,
      },
    }
  }

  useEffect(() => {
    onValueChange(currentValue)
  }, [currentValue])

  return (
    <DropdownSelectAsyncPaginate
      key={'outlet'}
      placeholder='Select Outlet'
      loadOptions={loadOptions}
      onChange={(newValues) => onValueChange(newValues)}
      value={currentValue}
      isDisabled={isDisabled}
      // className='min-w-300px'
      isClearable
      type='filled'
    />
  )
}

export {FilterDropdownOutlet}
