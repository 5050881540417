import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'

export type FilterProduct = {
  set_brand_partnership_id?: boolean
  brand_partnership_id?: string
  set_category_id?: boolean
  category_id?: string
  set_code_product?: boolean
  code_product?: string
  set_name?: boolean
  name?: string
  set_tags?: boolean
  tags?: string[]
  set_description?: boolean
  description?: string
  set_order_number?: boolean
  order_number?: number
}

export type Product = {
  id?: string
  name?: string
  tags?: string[]
  category?: Category
  coa?: Coa
  created_at?: string
  created_by?: string
  images_url?: string[]
  updated_at?: string
  updated_by?: string
  description?: string
  code_product?: string
  image_thumbnail_url?: string
  brand_partnership_id?: string
  variant?: Variant[]
  collection?: Collection[]
}

export type Coa = {
  id?: string
  account_code?: string
  account_name?: string
}

export type Variant = {
  id?: string
  sku?: string
  barcode?: string
  type?: string
  variant?: string
  combination?: Combination[]
  weight?: number
  length?: number
  width?: number
  height?: number
  created_at?: Date
  created_by?: string
  updated_at?: null
  updated_by?: null
}

export type Combination = {
  product_variant_id?: string
  type?: string
  variant?: string
}

export type Category = {
  id?: string
  name?: string
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
  description?: string
  order_number?: number
  code_category?: string
  image_thumbnail_url?: string
}

export type Collection = {
  id?: string
  id_mapping?: string
  code_collection?: string
  image_thumbnail_url?: string
  name?: string
  description?: string
}

export type VariantType = {
  product_variant_type?: string
}

export type LastSku = {
  sku?: string,
  sku_number?: number,
  barcode?: string,
  barcode_number?: number
}

export type ProductQueryResponse = BaseResponse<Array<Product>>

export const initialProduct: Product = {
  image_thumbnail_url: 'https://picsum.photos/400x400',
  name: 'Product A',
  category: {
    name: 'Category A',
  },
  description:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
}

export const initialFilter: BaseRequest<FilterProduct> = {
  filter: {
    set_brand_partnership_id: false,
    brand_partnership_id: '',
    set_category_id: false,
    category_id: '',
    set_code_product: false,
    code_product: '',
    set_name: false,
    name: '',
    set_tags: false,
    tags: [''],
    set_description: false,
    description: '',
    set_order_number: false,
    order_number: 0,
  },
  limit: 10,
  page: 1,
  order: 'created_at',
  sort: 'DESC',
}
