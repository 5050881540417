import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'

export type FilterProductModifier = {
  set_name?: boolean
  name_value?: string
  set_active?: boolean
  active_value?: boolean
}

export type FilterProductModifierRelation = {
  set_modifier_guid?: boolean
  modifier_guid_value?: string
  set_product_guid?: boolean
  product_guid_value?: string
  set_minimum_quantity_range?: boolean
  minimum_quantity_from_value?: number
  minimum_quantity_to_value?: number
  set_maximum_quantity_range?: boolean
  maximum_quantity_from_value?: number
  maximum_quantity_to_value?: number
  set_created_at_range?: boolean
  created_at_from_value?: string
  created_at_to_value?: string
}

export type ProductModifier = {
  id?: string
  name?: string
  active?: boolean
  is_active?: boolean
  minimum_quantity?: number,
  maximum_quantity?: number,
  created_at?: string
  created_by?: string
  updated_at?: string | null
  updated_by?: string | null
  modifier_detail?: ProductModifierDetail[]
  count_product_applied_modifier?: number
}

export type ProductModifierDetail = {
  modifier_detail_id?: string
  modifier_id?: string
  name?: string
  images?: string,
  description?: string,
  sell_price?: number
  minimum_quantity?: number
  maximum_quantity?: number
  is_sold_out: boolean
  status: boolean
  created_at: string
  created_by: string
  updated_at: string
  updated_by: string
}

export type ProductModifierRelation = {
  id?: string
  status?: boolean
  product?: ProductModifierRelationProductModifier
  modifier?: ProductModifierRelationProductModifier
  created_at?: string
  created_by?: string
  updated_at?: string | null
  updated_by?: string | null
  maximum_quantity?: number
  minimum_quantity?: number
}

type ProductModifierRelationProductModifier = {
  id?: string
  name?: string
}

export type ProductQueryResponse = BaseResponse<Array<ProductModifier>>

export type ProductRelationQueryResponse = BaseResponse<Array<ProductModifierRelation>>

export const initialProduct: ProductModifier = {
  id: "d518b8e2-7207-4b69-8756-82a0e6986f47",
  name: "Esspresso",
  active: true,
  created_at: "2024-05-15T04:43:05.404885",
  created_by: "System",
  updated_at: null,
  updated_by: null
}

export const initialFilter: BaseRequest<FilterProductModifier> = {
  filter: {
    set_name: false,
    name_value: "",
    set_active: false,
    active_value: true
  },
  limit: 25,
  page: 1,
  order: "created_at",
  sort: "DESC"
}

export const initialFilterProductRelation: BaseRequest<FilterProductModifierRelation> = {
  filter: {
    set_modifier_guid: false,
    modifier_guid_value: '',
    set_product_guid: false,
    product_guid_value: '',
    set_minimum_quantity_range: false,
    minimum_quantity_from_value: 1,
    minimum_quantity_to_value: 10,
    set_maximum_quantity_range: false,
    maximum_quantity_from_value: 5,
    maximum_quantity_to_value: 20,
    set_created_at_range: false,
    created_at_from_value: '2024-03-01T00:00:00Z',
    created_at_to_value: '2024-03-31T23:59:59Z'
  },
  limit: 1000,
  page: 1,
  order: "created_at",
  sort: "DESC"
}
