import {createContext, FC, useContext, useState} from 'react'
import {WithChildren} from '@/_metronic/helpers'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {FilterProductModifierRelation, initialFilterProductRelation} from './_models'
import { useDetailResponse } from './DetailResponseProvider'
import { useParams } from 'react-router-dom'
import { useListView } from './ListViewProvider'

type ProductModifierRelationRequestContextProps = {
  state: BaseRequest<FilterProductModifierRelation>
  updateState: (updates: Partial<BaseRequest<FilterProductModifierRelation>>) => void
}

const initialProductModifierRelationRequest = {
  state: initialFilterProductRelation,
  updateState: () => {},
}

const ProductModifierRelationRequestContext = createContext<ProductModifierRelationRequestContextProps>(initialProductModifierRelationRequest)

const ProductModifierRelationRequestProvider: FC<WithChildren> = ({children}) => {
  const {itemId} = useListView()
  const [state, setState] = useState<BaseRequest<FilterProductModifierRelation>>({
    ...initialProductModifierRelationRequest.state,
    filter: {
      ...initialProductModifierRelationRequest.state.filter,
      set_modifier_guid: itemId ? true : false,
      modifier_guid_value: itemId ? itemId : ""
    }
  })

  const updateState = (updates: Partial<BaseRequest<FilterProductModifierRelation>>) => {
    const updatedState = {...state, ...updates} as BaseRequest<FilterProductModifierRelation>
    setState(updatedState)
  }

  return (
    <ProductModifierRelationRequestContext.Provider value={{state, updateState}}>
      {children}
    </ProductModifierRelationRequestContext.Provider>
  )
}

const useProductModifierRelationRequest = () => useContext(ProductModifierRelationRequestContext)
export {ProductModifierRelationRequestProvider, useProductModifierRelationRequest}
