import { SubscriptionCustomerListSearchComponent } from "./SubscriptionCustomerListSearchComponent"
import { SubscriptionCustomerListToolbar } from "./SubscriptionCustomerListToolbar"

const SubscriptionCustomerListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <SubscriptionCustomerListSearchComponent />
      <div className='card-toolbar'>
        <SubscriptionCustomerListToolbar />
      </div>
    </div>
  )
}

export {SubscriptionCustomerListHeader}
