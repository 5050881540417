import {Link} from 'react-router-dom'
import {KTIcon} from '@/_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'
import { SubscriptionCustomerListFilter } from './SubscriptionCustomerListFilter'

const SubscriptionCustomerListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <SubscriptionCustomerListFilter />

      {/* begin::Add user */}
      {/* <Link to='/sales-marketing/subscription/add' reloadDocument>
        <button type='button' className='btn btn-primary'>
          <KTIcon iconName='plus' className='fs-2' />
          Add Subscription
        </button>
      </Link> */}
      {/* end::Add user */}
    </div>
  )
}

export {SubscriptionCustomerListToolbar}
