import {FilterEmployee} from '@/app/modules/human-resources/employee/core/_models'
import {getEmployees} from '@/app/modules/human-resources/employee/core/_requests'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {OptionType} from '@/_metronic/partials/content/dropdown-select/DropdownSelectAsyncPaginate'
import {DropdownSelectAsyncPaginateBody} from '@/_metronic/partials/content/dropdown-select/DropdownSelectAsyncPaginateBody'
import {useFormikContext} from 'formik'
import {FC, useEffect, useState} from 'react'
import {MultiValue} from 'react-select'
import { FilterSalesType } from '@/app/modules/product-price/core/_models'
import { getSalesTypes } from '@/app/modules/product-price/core/_requests'

type Props = {
  currentValue?: OptionType | MultiValue<OptionType> | null
  onValueChange?: any
  className?: string
  isDisabled?: boolean
  label?: string
}

const DropdownSalesType: FC<Props> = ({currentValue, onValueChange, isDisabled, label}) => {
  const [_, onChange] = useState<OptionType | MultiValue<OptionType> | null>(currentValue || null)
  const [selectedOption, setSelectedOption] = useState<any>()
  const {values, setFieldValue} = useFormikContext<any>()

  const loadOptionsSalesType = async (search: string, page: number) => {
    try {
      const body = {
        filter: {
          set_name: search !== "" ? true : false,
          name_value: search,
          set_mdr_percentage: false,
          mdr_percentage_value: 0
        },
        limit: 10,
        page,
        order: 'created_at',
        sort: 'DESC',
      } as BaseRequest<FilterSalesType>

      const resp = await getSalesTypes(body)
      if (resp?.response?.data) {
        const {data} = resp.response
        return {
          options: data?.map((x) => ({label: x.name, value: x.id})),
          hasMore: page < (resp?.response?.total_page || 0),
        }
      }

      return {options: [], hasMore: false, additional: page}
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      }
    }
  }

  const loadOptions: any = async (q, _, additional) => {
    const {page} = additional!
    const {options: responseOptions, hasMore} = await loadOptionsSalesType(q, page)
    const selectedOption = responseOptions.find((item) => item.label === "Mobile App")
    setSelectedOption(selectedOption)
    setFieldValue("sales_type", selectedOption)
    return {
      options: responseOptions,
      hasMore,
      additional: {
        page: page + 1,
      },
    }
  }

  useEffect(() => {
    onValueChange(currentValue)
  }, [currentValue])

  return (
    <div className='col-12 mb-10'>
      <label htmlFor='category-select' className='form-label'>
        {label}
      </label>
      <DropdownSelectAsyncPaginateBody
        key={'sales_type'}
        placeholder='Select Sales Type'
        loadOptions={loadOptions}
        onChange={(newValues) => onValueChange(newValues)}
        value={selectedOption}
        className='min-w-300px'
        isClearable
        isDisabled={true}
        cacheUniqs={[values?.outlet || 'po-receive-pic']}
      />
    </div>
  )
}

export {DropdownSalesType}
