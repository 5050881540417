import { SubscriptionListSearchComponent } from "@/app/modules/subscription/subscription-list/components/header/SubscriptionListSearchComponent"
import { SubscriptionListToolbar } from "@/app/modules/subscription/subscription-list/components/header/SubscriptionListToolbar"



const SubscriptionListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <SubscriptionListSearchComponent />
      <div className='card-toolbar'>
        <SubscriptionListToolbar />
      </div>
    </div>
  )
}

export {SubscriptionListHeader}
