import {FilterProduct, LastSku, Product, ProductQueryResponse, VariantType} from './_models'
import {AxiosResponse} from 'axios'
import {axios} from '@/_metronic/helpers/network/Axios'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'

const PRODUCT_API_URL = '/product-services'
const GET_PRODUCT_URL = `${PRODUCT_API_URL}/product/list`
const GET_PRODUCT_BY_ID_URL = `${PRODUCT_API_URL}/product`
const GET_LAST_SKU_NUMBER = `${PRODUCT_API_URL}/product/variant/last-sku`

/**
 * Product Region
 */
const getProducts = (body: BaseRequest<FilterProduct>): Promise<ProductQueryResponse> => {
  return axios
    .post(`${GET_PRODUCT_URL}`, body)
    .then((d: AxiosResponse<ProductQueryResponse>) => d.data)
}

const getProductById = (id: string | undefined): Promise<BaseResponse<Product>> => {
  return axios
    .get(`${GET_PRODUCT_BY_ID_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<Product>>) => d.data)
}

const createProduct = (body: any): Promise<BaseResponse<any>> => {
  return axios
    .post(GET_PRODUCT_BY_ID_URL, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updateProduct = (id: string, body: any): Promise<BaseResponse<any>> => {
  return axios
    .put(`${GET_PRODUCT_BY_ID_URL}/${id}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const deleteProduct = (id: string): Promise<BaseResponse<any>> => {
  return axios
    .delete(`${GET_PRODUCT_BY_ID_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

/**
 * Product Variant Type
 */

const getProductVariantType = (): Promise<BaseResponse<Array<VariantType>>> => {
  return axios
    .get(`${GET_PRODUCT_BY_ID_URL}/variant/type`)
    .then((d: AxiosResponse<BaseResponse<Array<VariantType>>>) => d.data)
}

const getProductLastSkuNumber = (): Promise<BaseResponse<LastSku>> => {
  return axios
    .get(`${GET_LAST_SKU_NUMBER}`)
    .then((d: AxiosResponse<BaseResponse<LastSku>>) => d.data)
}

/**
 * Product Variant
 */
const createProductVariant = (body: any): Promise<BaseResponse<any>> => {
  return axios
    .post(`${GET_PRODUCT_BY_ID_URL}/variant`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updateProductVariant = (id: string, body: any): Promise<BaseResponse<any>> => {
  return axios
    .put(`${GET_PRODUCT_BY_ID_URL}/variant/${id}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const deleteProductVariant = (id: string): Promise<BaseResponse<any>> => {
  return axios
    .delete(`${GET_PRODUCT_BY_ID_URL}/variant/${id}`)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

/**
 * Product Collection
 */

const addProductToCollection = (body: any): Promise<BaseResponse<any>> => {
  return axios
    .post(`${GET_PRODUCT_BY_ID_URL}/collection/mapping`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const removeProductFromCollection = (body: any): Promise<BaseResponse<any>> => {
  return axios
    .post(`${GET_PRODUCT_BY_ID_URL}/collection/mapping/remove`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

export {
  getProducts,
  getProductById,
  createProduct,
  updateProduct,
  deleteProduct,
  getProductVariantType,
  createProductVariant,
  updateProductVariant,
  deleteProductVariant,
  addProductToCollection,
  removeProductFromCollection,
  getProductLastSkuNumber
}
