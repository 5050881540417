import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { ProductModifierEditWrapper } from '@/app/modules/product-modifier/product-modifier-edit/ProductModifierEdit'
import { SubscriptionListWrapper } from '@/app/modules/subscription/subscription-list/SubscriptionList'
import { SubscriptionDetailWrapper } from '@/app/modules/subscription/subscription-detail/SubscriptionDetail'

const productsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Subscription',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const addProductsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Subscription',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Subscription List',
    path: '/sales-marketing/subscription/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SubscriptionPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/subscription/list'
          element={
            <>
              <PageTitle breadcrumbs={productsBreadcrumbs}>Subscription List</PageTitle>
              <SubscriptionListWrapper />
            </>
          }
        />
        <Route
          path='/subscription/add'
          element={
            <>
              <PageTitle breadcrumbs={addProductsBreadcrumbs}>Add Subscription</PageTitle>
              <SubscriptionDetailWrapper />
            </>
          }
        />
        <Route
          path='/subscription/edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={addProductsBreadcrumbs}>Edit Subscription</PageTitle>
              <SubscriptionDetailWrapper />
            </>
          }
        />
        <Route
          path='/subscription/detail/:id'
          element={
            <>
              <PageTitle breadcrumbs={addProductsBreadcrumbs}>Detail Subscription</PageTitle>
              <SubscriptionDetailWrapper />
            </>
          }
        />
        <Route index element={<Navigate to='/subscription/list' />} />
      </Route>
    </Routes>
  )
}

export default SubscriptionPage
