import {Link} from 'react-router-dom'
import {KTIcon} from '@/_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'
import { ProductModifierListFilter } from '@/app/modules/product-modifier/product-modifier-list/components/header/ProductModifierListFilter'

const ProductModifierListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <ProductModifierListFilter />

      {/* begin::Add user */}
      <Link to='/master-data/product/modifier/add' reloadDocument>
        <button type='button' className='btn btn-primary'>
          <KTIcon iconName='plus' className='fs-2' />
          Add Modifier
        </button>
      </Link>
      {/* end::Add user */}
    </div>
  )
}

export {ProductModifierListToolbar}
