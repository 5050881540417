import {useEffect, useMemo} from 'react'
import {useTable, ColumnInstance, Row, usePagination} from 'react-table'
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from '../../core/QueryResponseProvider'
import {productModifiersColumns} from './columns/_columns'
import {CustomRow} from './columns/CustomRow'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import axios from 'axios'
import {TableLoading} from '@/_metronic/partials/content/table-loading/TableLoading'
import {KTCardBody} from '@/_metronic/helpers'
import { SubscriptionHistory } from '../../core/_models'
import { SubscriptionHistoryListPagination } from '../components/pagination/SubscriptionHistoryListPagination'

const SubscriptionHistoryListTable = () => {
  const products = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const {page, totalCount} = useQueryResponsePagination()
  const data = useMemo(() => products, [products])
  const columns = useMemo(() => productModifiersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: page - 1},
      manualPagination: true,
      pageCount: totalCount,
    },
    usePagination
  )

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<SubscriptionHistory>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-dark mb-1 fs-6' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<SubscriptionHistory>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SubscriptionHistoryListPagination />
      {isLoading && <TableLoading />}
    </KTCardBody>
  )
}

export {SubscriptionHistoryListTable}
