import {
  FilterProduct,
  FilterProductPrice,
  FilterSalesType,
  Product,
  ProductByVariantQueryResponse,
  ProductPriceQueryResponse,
  ProductQueryResponse,
  SalesTypeQueryResponse,
  VariantType,
} from './_models'
import {AxiosResponse} from 'axios'
import {axios} from '@/_metronic/helpers/network/Axios'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import {ProductTreatmentRequest} from '../../order/core/_models'

const PRODUCT_API_URL = '/product-services'
const GET_PRODUCT_URL = `${PRODUCT_API_URL}/product/pos/list`
const GET_PRODUCT_BY_ID_URL = `${PRODUCT_API_URL}/product`
const PRODUCT_PRICE_VARIANT_URL = `${PRODUCT_API_URL}/product/pos/list`
const GET_LIST_PRODUCT_PRICE = `${GET_PRODUCT_BY_ID_URL}/price/list`
const CREATE_PRODUCT_PRICE = `${GET_PRODUCT_BY_ID_URL}/price`
const GET_LIST_SALES_TYPE = `${GET_PRODUCT_BY_ID_URL}/sales-type/list`

/**
 * Product Region
 */
const getProducts = (body: BaseRequest<FilterProduct>): Promise<ProductQueryResponse> => {
  return axios
    .post(`${GET_PRODUCT_URL}`, body)
    .then((d: AxiosResponse<ProductQueryResponse>) => d.data)
}

const getProductVariantMobile = (
  body: ProductTreatmentRequest
): Promise<ProductByVariantQueryResponse> => {
  return axios
    .post<ProductByVariantQueryResponse>(PRODUCT_PRICE_VARIANT_URL, body)
    .then((d: AxiosResponse<ProductByVariantQueryResponse>) => d.data)
}

const getProductById = (id: string | undefined): Promise<BaseResponse<Product>> => {
  return axios
    .get(`${GET_PRODUCT_BY_ID_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<Product>>) => d.data)
}

const createProduct = (body: any): Promise<BaseResponse<any>> => {
  return axios
    .post(GET_PRODUCT_BY_ID_URL, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updateProduct = (id: string, body: any): Promise<BaseResponse<any>> => {
  return axios
    .put(`${GET_PRODUCT_BY_ID_URL}/${id}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const deleteProduct = (id: string): Promise<BaseResponse<any>> => {
  return axios
    .delete(`${GET_PRODUCT_BY_ID_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

/**
 * Product Variant Type
 */

const getProductVariantType = (): Promise<BaseResponse<Array<VariantType>>> => {
  return axios
    .get(`${GET_PRODUCT_BY_ID_URL}/variant/type`)
    .then((d: AxiosResponse<BaseResponse<Array<VariantType>>>) => d.data)
}

/**
 * Product Variant
 */
const createProductVariant = (body: any): Promise<BaseResponse<any>> => {
  return axios
    .post(`${GET_PRODUCT_BY_ID_URL}/variant`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updateProductVariant = (id: string, body: any): Promise<BaseResponse<any>> => {
  return axios
    .put(`${GET_PRODUCT_BY_ID_URL}/variant/${id}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const deleteProductVariant = (id: string): Promise<BaseResponse<any>> => {
  return axios
    .delete(`${GET_PRODUCT_BY_ID_URL}/variant/${id}`)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

/**
 * Product Collection
 */

const addProductToCollection = (body: any): Promise<BaseResponse<any>> => {
  return axios
    .post(`${GET_PRODUCT_BY_ID_URL}/collection/mapping`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const removeProductFromCollection = (body: any): Promise<BaseResponse<any>> => {
  return axios
    .post(`${GET_PRODUCT_BY_ID_URL}/collection/mapping/remove`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

/**
 * Product Price
 */
const getProductPrices = (body: BaseRequest<FilterProductPrice>): Promise<ProductPriceQueryResponse> => {
  return axios
    .post(`${GET_LIST_PRODUCT_PRICE}`, body)
    .then((d: AxiosResponse<ProductPriceQueryResponse>) => d.data)
}
const createProductPrice = (body: any): Promise<BaseResponse<any>> => {
  return axios.post(CREATE_PRODUCT_PRICE, body).then((d) => d.data)
}

const updateProductPrice = (body: any): Promise<BaseResponse<any>> => {
  return axios.put(CREATE_PRODUCT_PRICE, body).then((d) => d.data)
}

const getSalesTypes = (body: BaseRequest<FilterSalesType>): Promise<SalesTypeQueryResponse> => {
  return axios
    .post(`${GET_LIST_SALES_TYPE}`, body)
    .then((d: AxiosResponse<SalesTypeQueryResponse>) => d.data)
}

export {
  getProducts,
  getProductVariantMobile,
  getProductById,
  createProduct,
  updateProduct,
  deleteProduct,
  getProductVariantType,
  createProductVariant,
  updateProductVariant,
  deleteProductVariant,
  addProductToCollection,
  removeProductFromCollection,
  createProductPrice,
  updateProductPrice,
  getProductPrices,
  getSalesTypes
}
