import {FilterProductModifier, FilterProductModifierRelation, ProductModifier, ProductModifierRelation, ProductQueryResponse, ProductRelationQueryResponse} from './_models'
import {AxiosResponse} from 'axios'
import {axios} from '@/_metronic/helpers/network/Axios'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import { Product } from '@/app/modules/order/core/_models'

const PRODUCT_API_URL = '/product-services'
const PRODUCT_MODIFIER_URL = `${PRODUCT_API_URL}/modifier`
const PRODUCT_MODIFIER_RELATION_URL = `${PRODUCT_API_URL}/product/modifier/product`
const GET_PRODUCT_MODIFIER_LIST_URL = `${PRODUCT_MODIFIER_URL}/list`
const PRODUCT_MODIFIER_STATUS = `${PRODUCT_MODIFIER_URL}/active`
const PRODUCT_MODIFIER_WITH_CHILD = `${PRODUCT_MODIFIER_URL}/with-child`
const PRODUCT_MODIFIER_RELATION_LIST = `${PRODUCT_MODIFIER_RELATION_URL}/list`

const getProductModifiers = (body: BaseRequest<FilterProductModifier>): Promise<ProductQueryResponse> => {
  return axios
    .post(`${GET_PRODUCT_MODIFIER_LIST_URL}`, body)
    .then((d: AxiosResponse<ProductQueryResponse>) => d.data)
}

const getProductModifierById = (id: string | undefined): Promise<BaseResponse<ProductModifier>> => {
  return axios
    .get(`${PRODUCT_MODIFIER_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<ProductModifier>>) => d.data)
}

const createProductModifier = (body: any): Promise<BaseResponse<any>> => {
  return axios
    .post(PRODUCT_MODIFIER_URL, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const createProductModifierWithChild = (body: any): Promise<BaseResponse<any>> => {
  return axios
    .post(PRODUCT_MODIFIER_WITH_CHILD, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updateProductModifier = (id: string, body: any): Promise<BaseResponse<any>> => {
  return axios
    .put(`${PRODUCT_MODIFIER_URL}/${id}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updateProductModifierWithChild = (id: string, body: any): Promise<BaseResponse<any>> => {
  return axios
    .put(`${PRODUCT_MODIFIER_WITH_CHILD}/${id}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const deleteProductModifier = (id: string): Promise<BaseResponse<any>> => {
  return axios
    .delete(`${PRODUCT_MODIFIER_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updateProductModifierStatus = (id: string): Promise<BaseResponse<any>> => {
  return axios
    .put(`${PRODUCT_MODIFIER_STATUS}/${id}`)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

// PRODUCT MODIFIER PRODUCT

const getProductModifierRelations = (body: BaseRequest<FilterProductModifierRelation>): Promise<ProductRelationQueryResponse> => {
  return axios
    .post(`${PRODUCT_MODIFIER_RELATION_LIST}`, body)
    .then((d: AxiosResponse<ProductRelationQueryResponse>) => d.data)
}

const getProductModifierRelationById = (id: string | undefined): Promise<BaseResponse<ProductModifierRelation>> => {
  return axios
    .get(`${PRODUCT_MODIFIER_RELATION_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<ProductModifierRelation>>) => d.data)
}

const createProductModifierRelation = (body: any): Promise<BaseResponse<any>> => {
  return axios
    .post(PRODUCT_MODIFIER_RELATION_URL, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updateProductModifierRelation = (id: string, body: any): Promise<BaseResponse<any>> => {
  return axios
    .put(`${PRODUCT_MODIFIER_RELATION_URL}/${id}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const deleteProductModifierRelation = (id: string): Promise<BaseResponse<any>> => {
  return axios
    .delete(`${PRODUCT_MODIFIER_RELATION_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

export {
  getProductModifiers,
  getProductModifierById,
  createProductModifier,
  updateProductModifier,
  deleteProductModifier,
  updateProductModifierStatus,
  createProductModifierWithChild,
  updateProductModifierWithChild,
  getProductModifierRelations,
  getProductModifierRelationById,
  createProductModifierRelation,
  updateProductModifierRelation,
  deleteProductModifierRelation
}
