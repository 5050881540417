import { BaseResponse } from '@/_metronic/helpers/crud-helper/base-list-response'
import { BaseRequest } from '@/_metronic/helpers/crud-helper/base-request'
import { axios } from '@/_metronic/helpers/network/Axios'
import { AxiosResponse } from 'axios'
import {
  CreateConfigurationRequest,
  CreateOutletRequest,
  FilterOutlet,
  FilterOutletOrganization,
  FilterPartnerOrganization,
  OrganizationRequest,
  OrganizationResponse,
  Outlet,
  OutletResponse,
  PartnerOrganizationResponse,
  UpdateConfigurationRequest
} from './_models'

const OUTLET_API_URL = '/outlet-services'
const GET_OUTLET_LIST = '/outlet/list'
const GET_OUTLET_BY_ID_URL = '/outlet'
const GET_OUTLET_ORGANIZATION_URL = '/outlet-organization'
const JOIN_OUTLET_AND_ORGANIZATION_URL = 'outlet/outlet-organization'
const GET_OUTLET_CONFIGURATION_URL = '/outlet/configuration'
const GET_OUTLET_ORGANIZATION_LIST = '/outlet-organization/list'
const GET_PARTNER_ORGANIZATION_LIST = '/partner-organization/list'
const PARTNERSHIP_CONTRACT = '/outlet/partner-organization-contract'
const GET_OUTLET_EXPORT_URL = '/excel/export'+GET_OUTLET_BY_ID_URL

const getOutlets = (body: BaseRequest<FilterOutlet>): Promise<OutletResponse> => {
  return axios
    .post(`${OUTLET_API_URL}${GET_OUTLET_LIST}`, body)
    .then((d: AxiosResponse<OutletResponse>) => d.data)
}

const getOutletOrganizations = (
  body: BaseRequest<FilterOutletOrganization>
): Promise<OrganizationResponse> => {
  return axios
    .post(`${OUTLET_API_URL}${GET_OUTLET_ORGANIZATION_LIST}`, body)
    .then((d: AxiosResponse<OrganizationResponse>) => d.data)
}

const getOutletById = (id: string | undefined): Promise<BaseResponse<Outlet>> => {
  return axios
    .get(`${OUTLET_API_URL}${GET_OUTLET_BY_ID_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<Outlet>>) => d.data)
}

const createOutlet = (body: CreateOutletRequest): Promise<BaseResponse<any>> => {
  return axios
    .post(`${OUTLET_API_URL}${GET_OUTLET_BY_ID_URL}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updateOutlet = (id: string | undefined, body: any): Promise<BaseResponse<any>> => {
  return axios
    .put(`${OUTLET_API_URL}${GET_OUTLET_BY_ID_URL}/${id}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const createOutletOrganization = (body: OrganizationRequest): Promise<BaseResponse<any>> => {
  return axios
    .post(`${OUTLET_API_URL}${GET_OUTLET_ORGANIZATION_URL}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const createJoinOutletAndOrganization = (body: any): Promise<BaseResponse<any>> => {
  return axios
    .post(`${OUTLET_API_URL}${JOIN_OUTLET_AND_ORGANIZATION_URL}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updateOutletOrganization = (
  id: string | undefined,
  body: OrganizationRequest
): Promise<BaseResponse<any>> => {
  return axios
    .put(`${OUTLET_API_URL}${GET_OUTLET_ORGANIZATION_URL}/${id}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updateJoinOutletAndOrganization = (
  id: string | undefined,
  body: any
): Promise<BaseResponse<any>> => {
  return axios
    .put(`${OUTLET_API_URL}${JOIN_OUTLET_AND_ORGANIZATION_URL}/${id}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const deleteOutlet = (id: string): Promise<BaseResponse<any>> => {
  return axios
    .delete(`${OUTLET_API_URL}${GET_OUTLET_BY_ID_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const createOutletConfiguration = (
  body: CreateConfigurationRequest
): Promise<BaseResponse<any>> => {
  return axios
    .post(`${OUTLET_API_URL}${GET_OUTLET_CONFIGURATION_URL}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updateOutletConfiguration = (
  id: string | undefined,
  body: UpdateConfigurationRequest
): Promise<BaseResponse<any>> => {
  return axios
    .put(`${OUTLET_API_URL}${GET_OUTLET_CONFIGURATION_URL}/${id}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const getPartnerOrganizations = (
  body: BaseRequest<FilterPartnerOrganization>
): Promise<PartnerOrganizationResponse> => {
  return axios
    .post(`${OUTLET_API_URL}${GET_PARTNER_ORGANIZATION_LIST}`, body)
    .then((d: AxiosResponse<PartnerOrganizationResponse>) => d.data)
}

const exportOutlet = (body: any): Promise<any> => {
  return axios
    .post(`${OUTLET_API_URL}${GET_OUTLET_EXPORT_URL}`, body, {
			responseType: 'blob',
		  })
    .then((d: AxiosResponse<any>) => d)
}

export {
  createJoinOutletAndOrganization, createOutlet,
  createOutletConfiguration,
  createOutletOrganization,
  deleteOutlet,
  getOutletById,
  getOutletOrganizations,
  getOutlets,
  getPartnerOrganizations, updateJoinOutletAndOrganization, updateOutlet,
  updateOutletConfiguration,
  updateOutletOrganization,
  exportOutlet
}

