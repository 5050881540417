import { ProductModifier } from '@/app/modules/product-modifier/core/_models'
import {FC} from 'react'
import { Toggle } from '@/_metronic/partials/content/toggle/Toggle'
import { updateSubscriptionStatus } from '../../../core/_requests'
import { RESP } from '@/_metronic/helpers/network/network-consts'
import { toast } from 'react-toastify'
import { useQueryResponse } from '../../../core/QueryResponseProvider'
import { Subscription } from '@/app/modules/subscription/core/_models'

type Props = {
  subscription: Subscription
}

const SubscriptionStatusCell: FC<Props> = ({subscription}) => {
  const {refetch} = useQueryResponse()
  const handleChange = async () => {
    if (subscription.id) {
      try {
        const res = await updateSubscriptionStatus(subscription.id)
        if (res.response?.code === RESP.SUCCESS) {
          toast.success(`${subscription.name} successfully updated`)
          refetch()
        }
      } catch (error) {
        toast.error("There's problem for this request")
      }
    }
  }

  return <Toggle
  // label="Is Active"
  id={`isActive`}
  checked={subscription.is_active}
  onChange={() => handleChange()}
/>
}

export {SubscriptionStatusCell}
