// @ts-nocheck
import {Column} from 'react-table'
import {SubscriptionStatusCell} from './SubscriptionStatusCell'
import { ProductModifier } from '@/app/modules/product-modifier/core/_models'
import { ProductModifierCustomHeader } from '@/app/modules/product-modifier/product-modifier-list/table/columns/ProductModifierCustomHeader'
import { formatIntl } from '@/app/utils'
import { SubscriptionCustomerActionsCell } from './SubscriptionCustomerActionsCell'
import { SubscriptionCustomer } from '../../../core/_models'

const productModifiersColumns: ReadonlyArray<Column<SubscriptionCustomer>> = [
  {
    Header: (props) => <ProductModifierCustomHeader tableProps={props} title='No' />,
    id: 'no',
    Cell: (props) => {
      return <label>{props.row.index + 1 + props.initialState.pageIndex * 10}</label>
    },
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'customer_name',
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Phone' className='min-w-125px' />
    ),
    accessor: 'customer_phone',
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Email' className='min-w-125px' />
    ),
    accessor: 'customer_email',
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Subscription' className='min-w-125px' />
    ),
    accessor: 'subscription',
    Cell: ({...props}) => {
      const data = props.data[props.row.index].subscription ? props.data[props.row.index].subscription.filter((item) => item.active).length : 0
      return (
        <label className={`fw-bold ${data > 0 ? 'text-success' : ''}`}>{data > 0 ? data : "-"}</label>
      )
    }
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <SubscriptionCustomerActionsCell id={props.data[props.row.index].customer_guid} />,
  },
]

export {productModifiersColumns}