/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {FormattedNumber, IntlProvider} from 'react-intl'
import {RushHourSummary} from '../core/_models'

type Props = {
  className: string
  data: RushHourSummary[] | undefined
}

const RushHourTable: React.FC<Props> = ({className, data}) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'></div>
      <div className='card-body'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-30px'>No</th>
                <th className='min-w-100px'>Time</th>
                <th className='min-w-150px'>Product</th>
                <th className='min-w-150px'>Product %</th>
                <th className='min-w-150px'>Transaction</th>
                <th className='min-w-150px'>Transaction %</th>
                <th className='min-w-150px'>Revenue</th>
                <th className='min-w-150px'>Revenue %</th>
              </tr>
            </thead>
            <tbody>
              {data !== undefined ? (
                data?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {index + 1}
                      </a>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {item.times}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item.total_qtys || 0}
                      </a>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 text-dark me-2 fs-7 fw-semibold'>
                          <IntlProvider locale='id'>
                            <FormattedNumber value={item.percentage_total_qtys || 0} />
                          </IntlProvider>{' '}
                          %
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {item.total_transaction || 0}
                      </a>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 text-dark me-2 fs-7 fw-semibold'>
                          <IntlProvider locale='id'>
                            <FormattedNumber value={item.percentage_total_transaction || 0} />
                          </IntlProvider>{' '}
                          %
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        Rp.{' '}
                        <IntlProvider locale='id'>
                          <FormattedNumber value={item.total_sales || 0} />
                        </IntlProvider>{' '}
                      </a>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 text-dark me-2 fs-7 fw-semibold'>
                          <IntlProvider locale='id'>
                            <FormattedNumber value={item.percentage_total_sales || 0} />
                          </IntlProvider>{' '}
                          %
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export {RushHourTable}
