/* eslint-disable jsx-a11y/anchor-is-valid */
import {useAuth} from '@/app/modules/auth'
import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../../../assets/ts/components'
import {KTIcon} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {DefaultTitle} from './page-title/DefaultTitle'

const HeaderToolbar = () => {
  const {classes} = useLayout()
  const location = useLocation()
  const {currentUser, logout} = useAuth()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [location])

  return (
    <div className='toolbar d-flex align-items-stretch'>
      {/* begin::Toolbar container */}
      <div
        className={`${classes.headerContainer.join(
          ' '
        )} py-6 py-lg-0 d-flex flex-row align-items-center justify-content-lg-between`}
      >
        <div style={{flex: 1}}>
          <DefaultTitle />
        </div>
        <div
          className='d-flex align-items-stretch overflow-auto pt-3 pt-lg-0'
          style={{flex: 'none'}}
        >
          {/* begin::Action wrapper */}
          <div className='d-flex align-items-center me-5'>
            {/* begin::Actions */}
            <div className='d-flex gap-3'>
              {/* begin::Notifications */}
              {/* <div className='d-flex align-items-center'>
                <a href='#' className='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary'>
                  <KTIcon iconName='add-files' className='fs-1' />
                </a>
              </div> */}
              {/* end::Notifications */}

              <div className='d-flex flex-column align-items-end'>
                <span className='fs-6 fw-bold'>{currentUser?.username || ''}</span>
                <div className='d-flex align-items-center justify-content-end gap-2'>
                  <KTIcon iconName='geolocation' className='d-flex fs-3' />
                  <span className='text-muted fs-6'>{currentUser?.detail_data?.outlet?.name}</span>
                </div>
              </div>

              {/* <div className='d-flex align-items-center'>
                <ThemeModeSwitcher toggleBtnClass='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary' />
              </div> */}
            </div>
            {/* end::Actions */}
          </div>
          {/* end::Action wrapper */}
          {/*begin::User*/}
          <div className='aside-user d-flex align-items-sm-center justify-content-center py-5'>
            {/*begin::Symbol*/}
            <div>
              <a
                href='#'
                className='symbol symbol-50px'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-overflow='false'
              >
                <img
                  src={currentUser?.detail_data?.url_profile_picture}
                  alt=''
                  style={{objectFit: 'cover'}}
                />
              </a>
              <HeaderUserMenu />
            </div>
            {/*end::Symbol*/}
          </div>
          {/*end::User*/}
        </div>
        {/* end::Toolbar container */}
      </div>
    </div>
  )
}

export {HeaderToolbar}
