import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { ProductModifierEditWrapper } from '@/app/modules/product-modifier/product-modifier-edit/ProductModifierEdit'
import { SubscriptionListWrapper } from '@/app/modules/subscription/subscription-list/SubscriptionList'
import { SubscriptionDetailWrapper } from '@/app/modules/subscription/subscription-detail/SubscriptionDetail'
import { SubscriptionHistoryListWrapper } from './SubscriptionHistoryList'

const productsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Subscription History',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SubscriptionHistoryPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/subscription/history/list'
          element={
            <>
              <PageTitle breadcrumbs={productsBreadcrumbs}>Subscription History List</PageTitle>
              <SubscriptionHistoryListWrapper />
            </>
          }
        />
        <Route index element={<Navigate to='/subscription/history/list' />} />
      </Route>
    </Routes>
  )
}

export default SubscriptionHistoryPage
