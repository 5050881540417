import { MenuComponent } from '@/_metronic/assets/ts/components'
import { KTIcon } from '@/_metronic/helpers'
import PropagateWrapper from '@/_metronic/partials/content/propagate/PropagateWrapper'
import { FilterDropdownCategory } from '@/app/modules/product-stock/product-stock-list/components/header/FilterDropdownCategory'
import { useEffect, useState } from 'react'
import { useQueryRequest } from '../../../core/QueryRequestProvider'
import { useQueryResponse } from '../../../core/QueryResponseProvider'
import { initialFilter } from '../../../core/_models'
import { DropdownSelect } from '@/_metronic/partials/content/dropdown-select/DropdownSelect'

const optionStatus = [
  {
    label: "Active",
    value: true
  },
  {
    label: "Non-Active",
    value: false
  }
]

const ProductModifierListFilter = () => {
  const {updateState} = useQueryRequest()
  const [filterValue, setFilterValue] = useState(initialFilter.filter)
  const [category, setCategory] = useState<any>(null)
  const {isLoading} = useQueryResponse()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setFilterValue(initialFilter.filter)
    setCategory(null)
    updateState({filter: initialFilter.filter, page: 1})
  }

  const filterData = () => {
    updateState({
      filter: filterValue,
      page: 1,
      ...updateState,
    })
  }

  return (
    <>
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <PropagateWrapper>
            {/* begin::Input group */}
            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold'>Name:</label>
              <input
                type='text'
                id='code'
                className='form-control form-control-solid'
                placeholder='Name'
                value={filterValue?.name_value}
                onChange={(e) =>
                  setFilterValue({
                    ...filterValue,
                    name_value: e.target.value,
                    set_name: Boolean(e.target.value),
                  })
                }
              />
            </div>
            {/* end::Input group */}

            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold'>Status:</label>
              <DropdownSelect
                options={optionStatus}
                value={optionStatus.find((item) => item.value === filterValue?.active_value)}
                onChange={(e) => setFilterValue({
                  ...filterValue,
                  active_value: e.value,
                  set_active: Boolean(e.value !== undefined)
                })}
              />
            </div>
          </PropagateWrapper>

          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export { ProductModifierListFilter }

