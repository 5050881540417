import { TopupHistoryListSearchComponent } from "./TopupHistoryListSearchComponent"
import { TopupHistoryListToolbar } from "./TopupHistoryListToolbar"

const TopupHistoryListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <TopupHistoryListSearchComponent />
      <div className='card-toolbar'>
        <TopupHistoryListToolbar />
      </div>
    </div>
  )
}

export {TopupHistoryListHeader}
