import {createContext, FC, useContext, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {
  initialQueryState,
  PaginationState,
  QUERIES,
  WithChildren,
  WITPaginationState,
} from '@/_metronic/helpers'
import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'
import {useQueryRequest} from './QueryRequestProvider'
import { FilterSubscriptionCustomer, initialFilter, initialFilterSubscriptionCustomer, SubscriptionCustomer } from './_models'
import { getSubscriptionCustomers } from './_requests'
import { useCustomerRequest } from './CustomerRequestProvider'

/**
 * Begin: Initial Values
 */
export const initialQueryResponse = {refetch: () => {}, isLoading: false, request: initialFilterSubscriptionCustomer}

/**
 * Begin: Response Context
 *
 * It used for context response wrapper, so the response can
 * called everywhere, as long as it wrapped inside the context
 */
type SubscriptionCustomerQueryResponseContextProps = {
  response?: BaseResponse<Array<SubscriptionCustomer>> | undefined
  refetch: () => void
  isLoading: boolean
  request: BaseRequest<FilterSubscriptionCustomer>
}

function createResponseContext<T>(initialState: SubscriptionCustomerQueryResponseContextProps) {
  return createContext(initialState)
}

const QueryResponseContext = createResponseContext<SubscriptionCustomer>(initialQueryResponse)

/**
 * Begin: The Provider
 */
const CustomerResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useCustomerRequest()
  const [request, setRequest] = useState<BaseRequest<FilterSubscriptionCustomer>>(state)
  const updatedQuery = useMemo(() => state, [state])

  useEffect(() => {
    if (request !== updatedQuery) {
      setRequest(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.SUBSCRIPTION_HISTORY_CUSTOMER}-list-${JSON.stringify(request)}`,
    () => {
      return getSubscriptionCustomers(request)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, request}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useCustomerResponse = () => useContext(QueryResponseContext)

const useCustomerResponseData = () => {
  const {response} = useCustomerResponse()
  if (!response) {
    return []
  }

  return response?.response?.data || []
}

const useCustomerResponsePagination = () => {
  const defaultPaginationState: WITPaginationState = {
    page: 1,
    pageSize: 10,
    totalCount: 1,
  }

  const {response} = useCustomerResponse()
  if (!response || !response.response) {
    return defaultPaginationState
  }
  const res = response.response
  return {
    page: res.current_page,
    pageSize: res.limit,
    totalCount: res.total_data,
  } as WITPaginationState
}

const useCustomerResponseLoading = (): boolean => {
  const {isLoading} = useCustomerResponse()
  return isLoading
}

export {
  CustomerResponseProvider,
  useCustomerResponse,
  useCustomerResponseData,
  useCustomerResponsePagination,
  useCustomerResponseLoading,
}
