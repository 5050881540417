import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu, ThemeModeSwitcher } from '../../../partials'
import { useAuth } from '@/app/modules/auth'

const itemClass = 'ms-1 ms-md-4'
const userAvatarClass = 'symbol-35px'

const Navbar = () => {

  const { currentUser, logout } = useAuth()

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass, 'd-none')}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column me-5'>
          <span className='fw-bold'>{currentUser?.detail_data?.fullname}</span>
            <span className='text-muted'>{currentUser?.detail_data?.email}</span>
          </div>
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={currentUser?.detail_data?.url_profile_picture} alt='' />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export { Navbar }
