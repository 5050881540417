import { ProductModifierListHeader } from '@/app/modules/product-modifier/product-modifier-list/components/header/ProductModifierListHeader'
import {KTCard} from '../../../../_metronic/helpers'
import {ListViewProvider, useListView} from './../core/ListViewProvider'
import {QueryRequestProvider} from './../core/QueryRequestProvider'
import {QueryResponseProvider} from './../core/QueryResponseProvider'
import {ProductModifierTable} from './table/ProductModifierListTable'
import { DetailResponseProvider } from '@/app/modules/product-modifier/core/DetailResponseProvider'
import { ProductRelationModal } from '@/app/modules/product-modifier/product-modifier-list/components/product-relation-modal/ProductRelationModal'
import { ProductModifierRelationRequestProvider } from '../core/ProductModifierRelationRequestProvider'
import { ProductModifierRelationResponseProvider } from '../core/ProductModifierRelationResponseProvider'

const ProductModifierList = () => {
  const {itemId, setItemId} = useListView()

  return (
    <>
      <KTCard className='shadow-wit-2'>
        <ProductModifierListHeader />
        <ProductModifierTable />
        {itemId !== undefined && (
          <DetailResponseProvider>
            <ProductModifierRelationRequestProvider>
              <ProductModifierRelationResponseProvider>
                <ProductRelationModal />
              </ProductModifierRelationResponseProvider>
            </ProductModifierRelationRequestProvider>
          </DetailResponseProvider>
        )}
      </KTCard>
    </>
  )
}

const ProductModifierListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ProductModifierList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ProductModifierListWrapper}
