// @ts-nocheck
import {Column} from 'react-table'
import {SubscriptionStatusCell} from './SubscriptionStatusCell'
import { ProductModifier } from '@/app/modules/product-modifier/core/_models'
import { ProductModifierCustomHeader } from '@/app/modules/product-modifier/product-modifier-list/table/columns/ProductModifierCustomHeader'
import { formatIntl } from '@/app/utils'
import { SubscriptionHistoryActionsCell } from './SubscriptionHistoryActionsCell'

const productModifiersColumns: ReadonlyArray<Column<ProductModifier>> = [
  {
    Header: (props) => <ProductModifierCustomHeader tableProps={props} title='No' />,
    id: 'no',
    Cell: (props) => {
      return <label>{props.row.index + 1 + props.initialState.pageIndex * 10}</label>
    },
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Customer' className='min-w-125px' />
    ),
    accessor: 'customer.customer_name',
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'subscription.subscription_name',
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Outlet' className='min-w-125px' />
    ),
    accessor: 'outlet.outlet_name',
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Sell Price' className='min-w-125px' />
    ),
    accessor: 'grandtotal_formatted',
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Expired Days' className='min-w-125px' />
    ),
    accessor: 'expired_days',
    Cell: (props) => {
      return props.value + ' day(s)'
    }
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'subscription_transaction_status',
    Cell: ({...props}) => {
      const status = props.data[props.row.index].subscription_transaction_status
      // draft biru, published ijo
      let className = 'badge bg-light-secondary'
      if (status?.toLowerCase() === 'awaiting payment') {
        className = 'badge bg-light-warning text-warning'
      } else if (status?.toLowerCase() === 'completed') {
        className = 'badge bg-light-success text-success'
      }
      
      return (
        <label className={className}>{props.data[props.row.index].subscription_transaction_status}</label>
      )
    },
  },
  {
    Header: (props) => (
      <ProductModifierCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <SubscriptionHistoryActionsCell id={props.data[props.row.index].subscription_transaction_guid} />,
  },
]

export {productModifiersColumns}