import { KTCard } from '@/_metronic/helpers'
import React, { useEffect, useRef, useState } from 'react'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { QueryRequestProvider as QueryRequestBillingClientProvider, useQueryRequest } from '../billing-client/core/QueryRequestProvider'
import { QueryResponseProvider as QueryResponseBillingClientProvider } from '../billing-client/core/QueryResponseProvider'
import { DropdownSelect } from '@/_metronic/partials/content/dropdown-select/DropdownSelect'
import { optionYearsNow } from '@/app/utils'
import { BillingClientExecutive } from '../billing-client/report-billing-client/table/BIllingClientExecutive'
import { useLocation } from 'react-router-dom'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { DetailResponseProvider } from './core/DetailResponseProvider'
import { TopupCustomerListTable } from './topup-customer-list/table/TopupCustomerListTable'
import { CustomerRequestProvider } from './core/CustomerRequestProvider'
import { CustomerResponseProvider } from './core/CustomerResponseProvider'
import { CustomerListViewProvider, useCustomerListView } from './core/CustomerListViewProvider'
import { TopupHistoryListHeader } from './topup-history-list/components/header/TopupHistoryListHeader'
import { TopupHistoryModal } from './topup-history-list/components/topup-history-modal/TopupHistoryModal'
import { TopupHistoryListTable } from './topup-history-list/table/TopupHistoryListTable'

const TopupHistoryList = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0); // Initial selected tab
  const tabRef = useRef<HTMLAnchorElement>(null)
  const handleTabClick = (index) => {
    setSelectedTabIndex(index);
  };
  const location = useLocation()
  const {itemId} = useListView()
  const {itemId: customerItemId} = useCustomerListView()

  useEffect(() => {
    if (location.search !== "") {
      setSelectedTabIndex(1)
      if (tabRef.current) {
        tabRef.current.click()
      }
    }
  }, [location.search])

  useEffect(() => {
    console.log(customerItemId)
  }, [customerItemId])
  
  return (
    <>
      <KTCard className='py-4 shadow-wit-2 mb-10'>
        <div className="d-flex row">
          <div className="col-lg-3 d-flex align-items-center">
            {/*begin:::Tabs*/}
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 ms-8'>
              {/*begin:::Tab item*/}
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-4 ${selectedTabIndex === 0 ? 'active' : ''}`}
                  data-bs-toggle='tab'
                  href='#kt_ecommerce_customer'
                  onClick={() => handleTabClick(0)}
                >
                  Customer
                </a>
              </li>
              {/*end:::Tab item*/}
              {/*begin:::Tab item*/}
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-4 ${selectedTabIndex === 1 ? 'active' : ''}`}
                  data-bs-toggle='tab'
                  href='#kt_ecommerce_history'
                  onClick={() => handleTabClick(1)}
                  ref={tabRef}
                >
                  History
                </a>
              </li>
              {/*end:::Tab item*/}
            </ul>
            {/*end:::Tabs*/}
          </div>
        </div>
      </KTCard>
      <div className='tab-content'>
        <div
          className='tab-pane fade show active'
          id='kt_ecommerce_customer'
        >
          <KTCard className='shadow-wit-2'>
            <TopupCustomerListTable />
          </KTCard>
        </div>
        <div
          className='tab-pane fade'
          id='kt_ecommerce_history'
        >
          <KTCard className='shadow-wit-2'>
            <TopupHistoryListHeader />
            <TopupHistoryListTable />
              {itemId !== undefined && (
                <DetailResponseProvider>
                  <TopupHistoryModal />
                </DetailResponseProvider>
              )}
          </KTCard>
        </div>
      </div>
    </>
  )
}

const TopupHistoryListWrapper = () => {
  return (
    <>
      <QueryRequestProvider>
        <CustomerRequestProvider>
          <QueryResponseProvider>
            <CustomerResponseProvider>
              <ListViewProvider>
                <CustomerListViewProvider>
                  <TopupHistoryList />
                </CustomerListViewProvider>
              </ListViewProvider>
            </CustomerResponseProvider>
          </QueryResponseProvider>
        </CustomerRequestProvider>
      </QueryRequestProvider>
    </>
  )
}

export { TopupHistoryListWrapper }
