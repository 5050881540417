import { BaseRequest } from '@/_metronic/helpers/crud-helper/base-request'
import { FilterCustomer, CustomerQueryResponse, Customer } from './_models'
import {AxiosResponse} from 'axios'
import {axios} from '@/_metronic/helpers/network/Axios'
import { BaseResponse } from '@/_metronic/helpers/crud-helper/base-list-response'

const OUTET_API_URL = '/outlet-services'
const GET_CUSTOMER = '/customer/list'
const GET_CUSTOMER_BY_ID_URL = '/customer'

const getCustomers = (
  body: BaseRequest<FilterCustomer>
): Promise<CustomerQueryResponse> => {
  return axios
    .post(`${OUTET_API_URL}${GET_CUSTOMER}`, body)
    .then((d: AxiosResponse<CustomerQueryResponse>) => d.data)
}

const getCustomerById = (id: string | undefined): Promise<BaseResponse<Customer>> => {
  return axios
    .get(`${OUTET_API_URL}${GET_CUSTOMER_BY_ID_URL}/detail/${id}`)
    .then((d: AxiosResponse<BaseResponse<Customer>>) => d.data)
}

const createCustomer = (body: Customer): Promise<BaseResponse<any>> => {
  return axios
    .post(`${OUTET_API_URL}${GET_CUSTOMER_BY_ID_URL}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updateCustomer = (id: string, body: Customer): Promise<BaseResponse<any>> => {
  return axios
    .put(`${OUTET_API_URL}${GET_CUSTOMER_BY_ID_URL}/${id}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const deleteCustomer = (id: string): Promise<BaseResponse<any>> => {
  return axios
    .delete(`${OUTET_API_URL}${GET_CUSTOMER_BY_ID_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

export {getCustomers, createCustomer, getCustomerById, updateCustomer, deleteCustomer}