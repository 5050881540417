import { ProductModifier } from '@/app/modules/product-modifier/core/_models'
import {FC} from 'react'
import { Toggle } from '@/_metronic/partials/content/toggle/Toggle'
import { updateProductModifierStatus } from '../../../core/_requests'
import { RESP } from '@/_metronic/helpers/network/network-consts'
import { toast } from 'react-toastify'
import { useQueryResponse } from '../../../core/QueryResponseProvider'

type Props = {
  product: ProductModifier
}

const ProductModifierStatusCell: FC<Props> = ({product}) => {
  const {refetch} = useQueryResponse()
  const handleChange = async () => {
    if (product.id) {
      try {
        const res = await updateProductModifierStatus(product.id)
        if (res.response === RESP.MSG_SUCCESS_CAPITALIZE) {
          toast.success(`${product.name} successfully updated`)
          refetch()
        }
      } catch (error) {
        toast.error("There's problem for this request")
      }
    }
  }

  return <Toggle
  // label="Is Active"
  id={`isActive`}
  checked={product.is_active}
  onChange={() => handleChange()}
/>
}

export {ProductModifierStatusCell}
